/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import { IoMdDownload } from 'react-icons/io';
import lscache from 'lscache';
import './style.scss';
import uploadAdmATAApi from '../../class/UploadAdmATA/upload-adm-ATA-api';
import { type SecretaryDocumentType } from '../../class/types/secretary-documents';
import UploadSecretaryDocumentApi from '../../class/UploadSecretaryDocuments/uploadSecretaryDocuments-api';

interface ContentTableKindergartenType {
  index: number
  secretaryDocument: SecretaryDocumentType
  secretaryDocuments: SecretaryDocumentType[]
  setSecretaryDocuments: React.Dispatch<React.SetStateAction<SecretaryDocumentType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplaySecretaryDocuments: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<SecretaryDocumentType>>
  type: 'projects-and-programs' | 'educational-laws'
}
export default function ContentTabeSecretaryDocuments({
  setSecretaryDocuments,
  secretaryDocument,
  secretaryDocuments,
  setDisplayEditElement,
  setDisplaySecretaryDocuments,
  setElementId,
  setElement,
  index,
  type
}: ContentTableKindergartenType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);

    const secretaryDocumentsApi = new UploadSecretaryDocumentApi();

    secretaryDocumentsApi.delete(type, String(secretaryDocument.id), secretaryDocument.location.fileName).then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }

      const elementIndex = secretaryDocuments.findIndex((value) => value.id === response?.id);

      if (typeof elementIndex !== 'number') {
        return;
      }

      secretaryDocuments.splice(elementIndex, 1);

      setSecretaryDocuments([...secretaryDocuments]);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(secretaryDocument.id));
    setDisplayEditElement(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(secretaryDocument);
    setDisplaySecretaryDocuments(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 30) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [secretaryDocument]);

  return (
    <div className={`content-table-element-adm-legislation display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={secretaryDocument.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(secretaryDocument.fileName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {secretaryDocument.issuer?.userName}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(secretaryDocument.date ?? '')}
          </h2>
        </li>
        <li>
          <a href={secretaryDocument.location ? secretaryDocument.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}

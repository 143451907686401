/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UserAPI from '../../../class/User/UserAPI';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import UpLoadKindergartenWeeklyPlan from '../../../class/UploadKindergartenSchoolWeeklyPlan/upload-weekly-plan-custumer';
import LoadContent from '../../../components/Load-content';
import DocumentSendingManagerApi from '../../../class/DocumentSendingManager/document-sending-manager-api';

interface FormAddWeeklyPlanProps {
  setDisplayFormAddWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolWeeklyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormAddWeeklyPlan: boolean
  elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType[]
}

export default function FormAddWeeklyPlan({ setDisplayFormAddWeeklyPlan, setElementarySchoolWeeklyPlan, displayFormAddWeeklyPlan, elementarySchoolWeeklyPlan }: FormAddWeeklyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isDisplayError, setIsDisplayError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string>('');

  const huddleCancel = () => {
    setDisplayFormAddWeeklyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCustomer = new UpLoadKindergartenWeeklyPlan(inputs);

    verifyDayOfWeekly().then(response => {
      if (!response) {
        huddleDisplayError();
        return;
      }

      setIsLoading(true);
      elementarySchoolMonthlyPlanCustomer.post().then(response => {
        if (!response) {
          setIsLoading(false);
          return;
        }
        setElementarySchoolWeeklyPlan([response, ...elementarySchoolWeeklyPlan]);
        setDisplayFormAddWeeklyPlan(false);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }).catch(e => {

    })
  };

  const verifyDayOfWeekly = async () => {
    const user = lscache.get('user');

    if (!user) {
      return false;
    }

    if (['coordenador', 'console'].includes(user.permission.rule)) {
      return true;
    }

    const documentSendingManager = new DocumentSendingManagerApi();

    const nowDate = new Date();

    let authorized = false

    await documentSendingManager.get('kindergarten-school-weekly-plan').then(response => {
      if (!response) {
        huddleDisplayError()
        return;
      }

      if (response.date.includes(nowDate.getDate())) {
        authorized = true;
        return;
      }

      setErrorMessages(`o sistema abrirá na proxima data de lançamento: ${response.nextDate}`);
    }).catch(e => {

    });

    return authorized;
  }

  const huddleDisplayError = () => {
    if (isDisplayError) {
      return;
    }
    setDisplayError(true);

    setIsDisplayError(true);
    setTimeout(() => {
      setIsDisplayError(false);
      setDisplayError(false);
    }, 5000);
  }

  const setDateHuddle = () => {
    const date = (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).split('/');

    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;

    setDate(formattedDate);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol
    if (user.permission?.rule === 'coordenador') {
      return;
    }

    setDateHuddle();
  }, [displayFormAddWeeklyPlan]);

  return (
    <div className={`form-add-school-kindergarten-teaching-weekly-plan display-${String(displayFormAddWeeklyPlan)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar novo Rotina Semanal
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddWeeklyPlan(false);
        }} size='25' />
      </div>
      <div className={`error-content display-${String(displayError)}`}>
        <h1>{errorMessages ?? ''}</h1>
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date" min={date} />
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-kindergarden-school-weekly-plan">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-kindergarden-school-weekly-plan'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import ContentTableElementaryDecentWork from '../../../components/decent-work';
import uploadDecentWorkApi from '../../../class/UploadDecentWork/upload-decent-work-api';
import EmptyComponent from '../../../components/Empty-component';

import FormDescribeDecentWork from '../form-description-decent-work-plan';
import FormEditDecentWork from '../form-edit-decent-work-plan';
import FormAddDecentWork from '../form-add-decent-work-plan';

import DecentWorkDisplayInformation from '../work-plan-display-information';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import ReadChecking from '../../../components/read-checking-decent-work';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import LoadContent from '../../../components/Load-content';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayFormDescribeElementaryDecentWork, setDisplayDescribeElementaryDecentWork] = useState(false);
  const [displayFormEditElementaryDecentWork, setDisplayEditElementaryDecentWork] = useState(false);
  const [displayFormAddElementaryDecentWork, setDisplayElementaryDecentWork] = useState(false);
  const [elementarySchoolDecentWorks, setElementarySchoolDecentWorks] = useState<ElementarySchoolDecentWorkType[]>([]);
  const [displayDecentWorkInfo, setDisplayDecentWorkInfo] = useState(false);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayReadChecking, setDisplayReadChecking] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();

  const huddleCreateNewElement = () => {
    setDisplayElementaryDecentWork(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, elementarySchoolDecentWorks);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    // UserAPI.getPermission(user.id).then(permission => {
    //   setIsLoading(false);
    //   uploadDecentWorkApi.get().then((response) => {
    //     if (permission === 'professor') {
    //       const decentWork = response.filter(value => value.issuer?.userId === user.id);

    //       setElementarySchoolDecentWorks(decentWork);
    //       setElementFiltered(decentWork);
    //       return;
    //     }
    //     setElementarySchoolDecentWorks(response);
    //     setElementFiltered(response);
    //   }).catch((e) => {
    //     //
    //   });
    // }).catch(e => {
    //   //
    // })
  }, [display]);

  useEffect(() => {
    setElementFiltered(elementarySchoolDecentWorks);
  }, [elementarySchoolDecentWorks]);

  return (
    <div className='table-container-elementary-school-teaching-work-plan'>
      <h1 className='title-table'>Ensino Fundamental - Plano de Trabalho docente</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadChecking
        displayReadChecking={displayReadChecking}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setElementarySchoolDecentWorks}
        elementId={elementId}
        type={'elementary-school'}
      />
      <FormAddDecentWork
        displayFormAddDecentWork={displayFormAddElementaryDecentWork}
        setDisplayFormAddDecentWork={setDisplayElementaryDecentWork}
        setElementarySchoolDecentWork={setElementarySchoolDecentWorks}
        elementarySchoolDecentWork={elementarySchoolDecentWorks}
      />
      <FormEditDecentWork
        displayFormEditDecentWork={displayFormEditElementaryDecentWork}
        setDisplayFormEditDecentWork={setDisplayEditElementaryDecentWork}
        setElementarySchoolDecentWork={setElementarySchoolDecentWorks}
        setDisplayEditElement={setDisplayEditElementaryDecentWork}
        elementId={elementId}
      />
      <FormDescribeDecentWork
        displayFormDescribeMonthlyPlan={displayFormDescribeElementaryDecentWork}
        setDisplayFormDescribeMonthlyPlan={setDisplayDescribeElementaryDecentWork}
        setElementarySchoolMonthlyPlan={setElementarySchoolDecentWorks}
        elementId={elementId}
      />
      <DecentWorkDisplayInformation
        displayDecentWorkInfo={displayDecentWorkInfo}
        setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
        element={element}
      />
      {/* <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar plano de trabalho docente</button>
      </div> */}
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar plano de trabalho docente) para adicionar um novo plano de trabalho docente</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryDecentWork
                      index={index}
                      elementarySchoolDecentWork={elementarySchoolDecentWork}
                      setDisplayReadChecking={setDisplayReadChecking}
                      setDisplayEditElement={setDisplayEditElementaryDecentWork}
                      setDisplayDescribeElementaryDecentWork={setDisplayDescribeElementaryDecentWork}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
                      setElementarySchoolDecentWork={setElementarySchoolDecentWorks}
                      type={'elementary-school'}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}

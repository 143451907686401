
import Validator from '../Validator';
import { type ElementarySchoolDecentWorkType } from '../types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import UploadWeeklyPlankApi from './upload-weekly-plan-api';
import lscache from 'lscache';

class UpLoadKindergartenSchoolWeeklyPlanCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly decentWorkId = '',
		private readonly textArea: HTMLTextAreaElement | undefined = undefined
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(): Promise<ElementarySchoolDecentWorkType | undefined> {
		if (!this.inputs) {
			return;
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return;
		}

		const file = this.inputs.item(2).files?.item(0);

		if (!file) {
			return;
		}

		const fileName = file.name ?? '';
		const date = this.inputs.item(1).value;
        const classRoom = this.inputs.item(0).value;

		const user = this._user;

		let newElementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType | undefined;

		await UploadWeeklyPlankApi.post({
			file,
      classRoom,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			newElementarySchoolWeeklyPlan = response;
		}).catch(e => {
			//
		});

		return newElementarySchoolWeeklyPlan;
	}

	async postDescription(): Promise<ElementarySchoolDecentWorkType | undefined> {
		if (!this.textArea) {
			return;
		}

		if (!this.textArea?.value) {
			Validator.displayError(this.textArea, 'o campo esta vazio');
			return;
		}

		const description = this.textArea.value;
		let newElementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType | undefined;

		await UploadWeeklyPlankApi.postDescription(this.decentWorkId, description, this._user.id ?? '', this._user.userName).then(response => {
			newElementarySchoolWeeklyPlan = response;
		});

		return newElementarySchoolWeeklyPlan;
	}

	async put(): Promise<ElementarySchoolDecentWorkType | undefined> {
		if (!this.inputs) {
			return;
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return;
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return;
		}

		const fileName = file.name ?? '';

		let newElementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType | undefined;

		await UploadWeeklyPlankApi.put({
			file,
			name: fileName,
			weeklyPlanId: this.decentWorkId,
		}).then(response => {
			newElementarySchoolWeeklyPlan = response;
		});

		return newElementarySchoolWeeklyPlan;
	}
}

export default UpLoadKindergartenSchoolWeeklyPlanCostumer;

/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UpLoadElementarySchoolHTTPICostumer from '../../../class/UploadElementarySchoolHTTPI/upload-HTTPI-custumer';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import LoadContent from '../../../components/Load-content';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';

interface FormAddWeeklyPlanProps {
  elementarySchoolHTTPI: ElementarySchoolDecentWorkType[]
  setElementarySchoolHTTPI: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  setDisplayFormAddHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  displayFormAddHTTPI: boolean
}

export default function FormAddHTTPI({ setDisplayFormAddHTTPI, setElementarySchoolHTTPI, elementarySchoolHTTPI, displayFormAddHTTPI}: FormAddWeeklyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');

  const huddleCancel = () => {
    setDisplayFormAddHTTPI(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolHTTPICustomer = new UpLoadElementarySchoolHTTPICostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    elementarySchoolHTTPICustomer.post().then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }

      setElementarySchoolHTTPI([response, ...elementarySchoolHTTPI]);

      setDisplayFormAddHTTPI(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  const setDateHuddle = () => {
    const date = (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).split('/');

    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;

    setDate(formattedDate);
  }

  useEffect(() => {
    setDateHuddle();
  }, [displayFormAddHTTPI]);

  return (
    <div className={`form-add-elementary-school-HTTPI display-${String(displayFormAddHTTPI)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar nova HTTPI
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddHTTPI(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date"
            // min={date}
          />
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-elementary-school-HTTPI">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-elementary-school-HTTPI'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

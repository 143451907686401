/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadElementarySchoolHTTPIApi from '../../class/UploadElementarySchoolHTTPI/upload-HTTPI-api';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import { MdDelete, MdDescription } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DisplayFileInfoTree } from '../DisplayFile';
import RuleVerification from '../rule-verification';
import LoadContent from '../Load-content';
import { FiEdit2 } from 'react-icons/fi';
import lscache from 'lscache';
import './style.scss';
import { HiCheck } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableKindergartenType {
  index: number
  elementarySchoolHTTPI: ElementarySchoolDecentWorkType
  HTTPI: ElementarySchoolDecentWorkType[]
  setDisplayDescribeElementarySchoolHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolHTTPI: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayChecking: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
  type: 'elementary-school' | 'kindergartenSchool'
}
export default function ContentTableElementaryHTTPI({
  setDisplayDescribeElementarySchoolHTTPI,
  setElementarySchoolHTTPI,
  HTTPI,
  elementarySchoolHTTPI,
  setDisplayEditElement,
  setDisplayChecking,
  setDisplayHTTPI,
  setElementId,
  setElement,
  index,
  type
}: ContentTableKindergartenType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    uploadElementarySchoolHTTPIApi.delete(String(elementarySchoolHTTPI.id), elementarySchoolHTTPI.location.fileName).then(response => {
      if (!response) {
        return;
      }
      const indexElement = HTTPI.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }
      HTTPI.splice(indexElement, 1);

      setElementarySchoolHTTPI([...HTTPI]);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(elementarySchoolHTTPI.id));
    setDisplayEditElement(true);
  };

  const huddleDescribe = () => {
    setElementId(String(elementarySchoolHTTPI.id));

    setDisplayDescribeElementarySchoolHTTPI(true);
  };

  const huddleSetChecking = () => {
    setElementId(String(elementarySchoolHTTPI.id));

    setDisplayChecking(true);
  }

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(elementarySchoolHTTPI);
    setDisplayHTTPI(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolHTTPI]);

  return (
    <div className={`content-table-element-httpi display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={elementarySchoolHTTPI.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(elementarySchoolHTTPI.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {elementarySchoolHTTPI.classRoom}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(elementarySchoolHTTPI.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {elementarySchoolHTTPI.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={elementarySchoolHTTPI.location ? elementarySchoolHTTPI.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <MdDescription size='20' style={{ color: '#118951' }} onClick={huddleDescribe} />
          </li>
        </RuleVerification>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={huddleSetChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
      </ul>
    </div>
  );
}

/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';
import './style.scss';
import InputSelect from '../../../components/input-select';
import { type DataWritingLevel, type TagTitleList } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import LoadingContent from '../../../components/Load-content';
import generatePDF from './service/generetePDF';

interface DashBoardWritingLevelProps {
  displayDashboardWritingLevel: boolean
  setDisplayDashboardWritingLevel: React.Dispatch<React.SetStateAction<boolean>>
  roomInfo: Record<string, string>
  displayList: TagTitleList[]
}

const defaultDataDashboard = {
  colors: ['#ff0000'],
  categories: ['dados não encontrado'],
  series: [100]
};

export default function DashboardWritingLevel({ displayDashboardWritingLevel, displayList, setDisplayDashboardWritingLevel, roomInfo }: DashBoardWritingLevelProps): React.ReactElement {
  const [categories, setCategories] = useState<any[]>(defaultDataDashboard.categories);
  const [series, setSeries] = useState<any[]>(defaultDataDashboard.series);
  const [seriesCount, setSeriesCount] = useState<number[]>(defaultDataDashboard.series);
  const [colors, setColors] = useState<any[]>([]);
  const [writingTheName, setWritingTheName] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState<string>('');
  const [nrLength, setNRLength] = useState<number>(0);

  const roomName = useParams().roomName;

  const { id } = useParams();

  const huddleDisplayDashboardWritingLevel = () => {
    setDisplayDashboardWritingLevel(!displayDashboardWritingLevel);
  };

  const hundleSelect = (option: string) => {
    let _index = 0;
    setOption(option);
    const element = displayList.find((value, index) => {
      if (value.name.includes(option)) {
        _index = index;
        return true;
      }

      return false;
    });
    if (!id) {
      return;
    }
    if (_index === 0) {
      setIsLoading(true);
      studentWritingLevelRecordApi.getDataWritingLevel(id, roomInfo.twoMonths).then(response => {
        const responseFormat = removeNR(response);

        setIsLoading(false);
        setCategories(responseFormat.category);
        setSeries(responseFormat.series);
        setSeriesCount(responseFormat.seriesCount);
        setColors(responseFormat.color);
        setWritingTheName(responseFormat.writingTheName);
      });
      return;
    }

    setIsLoading(true);
    studentWritingLevelRecordApi.getDateSpecificTagTitle(id, roomInfo.twoMonths, element?.id ?? '', _index).then((response: any) => {
      const responseFormat = removeNR(response);

      setIsLoading(false);
      setSeries(responseFormat.series);
      setColors(responseFormat.color);
      setCategories(responseFormat.category);
      setSeriesCount(responseFormat.seriesCount);
    });
  }

  const printGraphic = () => {
    generatePDF(roomName ?? '', roomInfo.twoMonthsName, option);
  }

  const removeNR = (response: DataWritingLevel): DataWritingLevel => {
    let NRindex = 0;
    let NRSearched = false;
    if (!response) {
      return {
        category: [],
        color: [],
        series: [],
        seriesCount: [],
        writingTheName: 0,
      };
    }
    response.category.map((value: any, index: number) => {
      if (NRSearched) {
        return value;
      }
      if (value === 'NR') {
        NRindex = index;
        NRSearched = true;
      }
      return value;
    });

    setNRLength(!response.seriesCount ? 0 : response.seriesCount[NRindex]);

    response.category.splice(NRindex, 1);
    response.series.splice(NRindex, 1);

    return response;
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    studentWritingLevelRecordApi.getDataWritingLevel(id, roomInfo.twoMonths).then(response => {
      const responseFormat = removeNR(response);

      setCategories(responseFormat.category);
      setSeries(responseFormat.series);
      setSeriesCount(responseFormat.seriesCount);
      setColors(responseFormat.color);
      setWritingTheName(responseFormat.writingTheName);
    });
  }, [displayDashboardWritingLevel]);

  if (!displayDashboardWritingLevel) {
    return <></>;
  }
  return (
    <div className={`dashboard-writing-level display-${displayDashboardWritingLevel}`}>
      <div className='button-content'>
        <button type='button' onClick={huddleDisplayDashboardWritingLevel}>Voltar</button>
        <button type='button' onClick={printGraphic}>Gerar PDF</button>
        <div>
          <InputSelect
            options={displayList.map(value => value.name)}
            title='Tags'
            defaultValue='Escolha uma tag'
            cb={hundleSelect}
          />
        </div>
      </div>
      {!isLoading ? (
        <div id='graphic-print'>
          <Chart
            className='graphic-content'
            type='pie'
            width={window.innerWidth < 550 ? 400 : 700}
            series={series}
            options={{
              labels: categories,
              colors,
              legend: {
                fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                offsetY: 70
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 0.5,
                dashArray: 0
              },
              chart: {
                width: '50px',
                height: '50px'
              },
              title: {
                align: 'center',
                margin: 30,
                style: {
                  fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                },
                text: `Hipótese de Escrita | ${roomName} - ${roomInfo.twoMonthsName}`
              }
            }}
          />
          <Chart
            className='graphic-content'
            type='bar'
            width={window.innerWidth < 550 ? 350 : 700}
            options={{
              chart: {
                width: 800,
                type: 'bar'
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 1,
                dashArray: 0
              },
              colors,
              dataLabels: {
                enabled: true,
                formatter(val) {
                  return `${val}%`;
                },
                offsetY: 10,
                style: {
                  fontSize: `${window.innerWidth < 550 ? '7px' : '12px'}`,
                  colors: ['#304758']
                }
              },
              legend: {
                show: false
              },
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: true,
                  dataLabels: {
                    position: 'top' // Top, center, bottom
                  }
                }
              },
              xaxis: {
                categories,
                labels: {
                  style: {
                    colors: '#000000',
                    fontWeight: '600',
                    fontSize: `${window.innerWidth < 550 ? '6px' : '10px'}`
                  }
                }
              }
            }}
            series={[
              {
                data: series
              }
            ]}
          />
          <h1>{('Quantidade de alunos que escreve o próprio nome:  ' + `${writingTheName}`).toUpperCase()}</h1>
          <div className='category-count-information'>
            <ul>
              <li>
                <h1>Total avaliado: {seriesCount.reduce((previousValue, value) => previousValue += value, 0)}</h1>
              </li>
              {categories.map((category: string, index) => {
                return (
                  <li key={`${category.replaceAll(' ', '').concat(`${seriesCount[index]}`)}`}>
                    <div
                      className='color-content'
                      style={
                        {
                          backgroundColor: colors[index],
                        }
                      }
                    >
                    </div>
                    <h1>
                      {category}: {seriesCount[index]}
                    </h1>
                  </li>
                )
              })}
              <li>
                <h1>
                  Não Realizado: {nrLength}
                </h1>
              </li>
            </ul>
          </div>
        </div>
      ) : <LoadingContent isLoading={isLoading} />}
    </div>
  );
}

/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useEffect, useState } from 'react';
import './style.scss';
import { FiLayers, FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { BsFillGearFill } from 'react-icons/bs';
import { RiListSettingsFill, RiMentalHealthLine } from 'react-icons/ri';
import { LuNetwork } from 'react-icons/lu'
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import RuleVerification from '../../rule-verification';
import { FaFileAlt } from 'react-icons/fa';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
}

export default function Nav({ setElements, elements }: NavProps): React.ReactElement {
  const user = lscache.get('user');

  const [display, setDisplay] = useState(false);
  const [displayRoms, setDisplayRoms] = useState(false);
  const [displayLogout, setDisplayLogout] = useState(false);
  const [displayEdu1, setDisplayEdu1] = useState(false);
  const [displayEdu2, setDisplayEdu2] = useState(false);
  const [displayEdu3, setDisplayEdu3] = useState(false);
  const [displayEdu4, setDisplayEdu4] = useState(false);
  const [displayEdu5, setDisplayEdu5] = useState(false);
  const [displayEdu6, setDisplayEdu6] = useState(false);
  const [displayEdu7, setDisplayEdu7] = useState(false);
  const [displayEdu8, setDisplayEdu8] = useState(false);
  const [displayEdu9, setDisplayEdu9] = useState(false);
  const [displayEdu10, setDisplayEdu10] = useState(false);
  const [displayEdu11, setDisplayEdu11] = useState(false);
  const [displayEdu12, setDisplayEdu12] = useState(false);
  const [displayEdu13, setDisplayEdu13] = useState(false);
  const [displayEdu14, setDisplayEdu14] = useState(false);
  const [displayEdu15, setDisplayEdu15] = useState(false);
  const [displayEdu16, setDisplayEdu16] = useState(false);
  const [displayEdu17, setDisplayEdu17] = useState(false);

  const [displayNav, setDisplayNav] = useState<boolean>(false);

  const [rule, setRule] = useState('');

  const onClickLi = (elementName: string) => {
    const elementsResected = resetElements(elements);

    elementsResected.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsResected);
    setDisplayNav(false);
  };

  const huddleDisplay = (set: (e: React.SetStateAction<boolean>) => void, get: boolean): void => {
    set(!get);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  const logout = () => {
    lscache.remove('user');

    window.location.href = `${window.location.origin}/login`;
  };

  const huddleDisplayNav = () => {
    setDisplayNav(!displayNav);
  }

  useEffect(() => {
    UserAPI.getPermission(user.id).then(rule => {
      if (rule) {
        setRule(rule);
      }
    });
  }, []);

  return (
    <>
      <div className={`display-nav-button-content display-${displayNav}`}>
        <button onClick={huddleDisplayNav} className='button-display-nav-element'></button>
      </div>
      <nav className={`nav-component-home display-${displayNav}`}>
        <div onClick={() => {
          huddleDisplay(setDisplayLogout, displayLogout);
        }} className='strategy-title'>
          <div className={`display-${displayLogout}`}>
            <h1>{user.userName}</h1>
            <p>
              id:
              <span>{user.id}</span>
            </p>
          </div>
          <div className='icon-content'>
            <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayLogout}`} />
          </div>
        </div>
        <ul className='ul-option'>
          <ul className={`logout-user-content display-${displayLogout}`}>
            <li onClick={() => {
              onClickLi('strategy-8');
            }}>
              <h1>Configuração</h1>
              <BsFillGearFill className='logout-icon' size='20' />
            </li>
            <li onClickCapture={logout}>
              <h1>Sair da conta</h1>
              <FiLogOut className='logout-icon' size='20' />
            </li>
          </ul>
          <RuleVerification rule={['coordenador']}>
            <>
              <li className='option-content-1' onClickCapture={() => {
                huddleDisplay(setDisplayEdu8, displayEdu8);
              }}>
                <LuNetwork className='' color='#FFF' size='20' />
                <div className='option-title-content'>
                  <h1>Secretaria Municipal</h1>
                  <IoIosArrowDown size='20' className={`icon-arrow display-${displayEdu8}`} />
                </div>
              </li>
              <ul className={`management-options display-${displayEdu8}`}>
                <li className='option-element' id='estrategy-1' onClick={() => {
                  onClickLi('strategy-1');
                }}
                >
                  <h1 id='estrategy-1'>
                    Objetivos estratégicos
                  </h1>
                </li>
                <li className='option-element' id='estrategy-1' onClick={() => {
                  onClickLi('strategy-6');
                }}
                >
                  <h1 id='estrategy-1'>
                    Plano municipal pela primeira infância
                  </h1>
                </li>
                <li className='option-element' id='estrategy-1' onClick={() => {
                  onClickLi('strategy-45');
                }}
                >
                  <h1 id='estrategy-1'>
                    Ficha De Monitoramento do Plano Municipal De Educação
                  </h1>
                </li>
                <RuleVerification rule={['coordenador']}>
                  <li className='option-element' onClick={() => {
                    huddleDisplay(setDisplayEdu17, displayEdu17);
                  }}>
                    <div className='option-element-title-content'>
                      <h1>Documentos</h1>
                      <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu17}`} />
                    </div>
                  </li>
                </RuleVerification>
                <li className={'option-element-rooms'}>
                  <ul className={`option-of-rooms display-${displayEdu17}`}>
                    <li className='option-element' onClick={() => {
                      onClickLi('strategy-52');
                    }}>
                      Projetos e programas
                    </li>
                    <li className='option-element' onClick={() => {
                      onClickLi('strategy-53');
                    }}>
                      Leis educacionais
                    </li>
                    <li className='option-element' onClick={() => {
                      onClickLi('strategy-54');
                    }}>
                      Gerenciamento de Recursos
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          </RuleVerification>
          <RuleVerification rule={['professor', 'coordenador', 'nutricionista', 'psicologo']}>
            <>
              <RuleVerification rule={['professor', 'coordenador', 'nutricionista', 'psicologo']}>
                <li className='option-content-2 option-content-1' onClickCapture={() => {
                  huddleDisplay(setDisplay, display);
                }}>
                  <FiLayers size='20' color='#fff' />
                  <div className='option-title-content'>
                    <h1>Gestão</h1>
                    <IoIosArrowDown size='20' className={`icon-arrow display-${display}`} />
                  </div>
                </li>
              </RuleVerification>
              <ul className={`management-options display-${display}`}>
                <RuleVerification rule={['professor', 'coordenador', 'psicologo']}>
                  <li className='option-element' onClick={() => {
                    huddleDisplay(setDisplayRoms, displayRoms);
                  }}>
                    <div className='option-element-title-content'>
                      <h1>Gestão de turma</h1>
                      <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayRoms}`} />
                    </div>
                  </li>
                </RuleVerification>
                <li className={'option-element-rooms'}>
                  <ul className={`option-of-rooms display-${displayRoms}`}>
                    <RuleVerification rule={['coordenador', 'psicologo']}>
                      <>
                        <li className='option-element' onClick={() => {
                          onClickLi('strategy-5');
                        }}>
                          Alunos
                        </li>
                        <li className='option-element' onClick={() => {
                          onClickLi('strategy-50');
                        }}>
                          Alunos que estão em espera
                        </li>
                      </>
                    </RuleVerification>
                    <RuleVerification rule={['professor']}>
                      <li onClick={() => {
                        onClickLi('strategy-7');
                      }}>Minha Sala</li>
                    </RuleVerification>
                    <RuleVerification rule={['coordenador']}>
                      <li onClick={() => {
                        onClickLi('strategy-4');
                      }}>Salas</li>
                    </RuleVerification>
                  </ul>
                </li>
                <li className='option-element' onClick={() => {
                  huddleDisplay(setDisplayEdu10, displayEdu10);
                }}>
                  <div className='option-element-title-content'>
                    <h1>Ferramentas Pedagógicas</h1>
                    <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu10}`} />
                  </div>
                </li>
                <li className={'option-element-rooms'}>
                  <ul className={`option-of-rooms display-${displayEdu10}`}>
                    <RuleVerification rule={['professor', 'coordenador', 'nutricionista']}>
                      <>
                        <RuleVerification rule={['professor', 'coordenador']}>
                          <li className='option-element option-education' onClickCapture={() => {
                            huddleDisplay(setDisplayEdu1, displayEdu1);
                          }}>
                            <div className='option-element-title-content'>
                              <h1>Ensino Infantil</h1>
                              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu1}`} />
                            </div>
                          </li>
                        </RuleVerification>
                        <ul className={`option-element-rooms-edu display-${displayEdu1}`}>
                          <li className='option-element option-education' onClickCapture={() => {
                            huddleDisplay(setDisplayEdu6, displayEdu6);
                          }}>
                            <div className='option-element-title-content'>
                              <h1>HTTPI</h1>
                              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu6}`} />
                            </div>
                          </li>
                          <ul className={`option-element-rooms-edu monthly-plan-options display-${displayEdu6}`}>
                            <li onClickCapture={() => {
                              onClickLi('strategy-20');
                            }}>Documentos</li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-21');
                            }}>HTTPI Digital</li>
                          </ul>
                          <li onClickCapture={() => {
                            onClickLi('strategy-14');
                          }}>Plano Mensal</li>
                          {/* <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-13');
                          }}>Plano de Trabalho docente</li> */}
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-16');
                          }}>
                            Rotina Semanal
                          </li>
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-23');
                          }}>
                            Relatório do Estudante
                          </li>
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-25');
                          }}>
                            ATA
                          </li>
                          {/* <li className='option-element' onClickCapture={() => {
                          onClickLi('strategy-16');
                        }}>
                          ATA
                        </li> */}
                          {/* <li className='option-element' onClickCapture={() => {
                          onClickLi('strategy-20');
                        }}>HTTPI</li> */}
                        </ul>
                      </>
                    </RuleVerification>
                    <RuleVerification rule={['professor', 'coordenador']}>
                      <>
                        <li className='option-element option-education' onClickCapture={() => {
                          huddleDisplay(setDisplayEdu2, displayEdu2);
                        }}>
                          <div className='option-element-title-content'>
                            <h1>Ensino Fundamental</h1>
                            <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu2}`} />
                          </div>
                        </li>
                        <ul className={`option-element-rooms-edu display-${displayEdu2}`}>
                          <RuleVerification rule={['coordenador', 'professor']}>
                            <>
                              <li className='option-element option-education' onClickCapture={() => {
                                huddleDisplay(setDisplayEdu7, displayEdu7);
                              }}>
                                <div className='option-element-title-content'>
                                  <h1>HTTPI</h1>
                                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu7}`} />
                                </div>
                              </li>
                              <ul className={`option-element-rooms-edu monthly-plan-options display-${displayEdu7}`}>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-19');
                                }}>Documentos</li>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-22');
                                }}>HTTPI Digital</li>
                              </ul>
                              <li onClick={() => {
                                onClickLi('strategy-15');
                              }}>Plano Mensal</li>
                              {/* <li className='option-element' onClick={() => {
                                onClickLi('strategy-12');
                              }}
                              >Plano de Trabalho docente</li> */}
                              <li className='option-element' onClickCapture={() => {
                                onClickLi('strategy-17');
                              }}>
                                Rotina Semanal
                              </li>
                              <li className='option-element' onClickCapture={() => {
                                onClickLi('strategy-24');
                              }}>
                                Relatório do Estudante
                              </li>
                            </>
                          </RuleVerification>
                          {/* <li className='option-element' onClickCapture={() => {
                          onClickLi('strategy-19');
                        }}>
                          HTTPI
                        </li> */}
                        </ul>
                        <RuleVerification rule={['professor', 'coordenador']}>
                          <li className='option-element option-education' onClickCapture={() => {
                            huddleDisplay(setDisplayEdu12, displayEdu12);
                          }}>
                            <div className='option-element-title-content'>
                              <h1>Oficinas</h1>
                              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu12}`} />
                            </div>
                          </li>
                        </RuleVerification>
                        <ul className={`option-element-rooms-edu display-${displayEdu12}`}>
                          <li className='option-element option-education' onClickCapture={() => {
                            huddleDisplay(setDisplayEdu13, displayEdu13);
                          }}>
                            <div className='option-element-title-content'>
                              <h1>HTTPI</h1>
                              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu13}`} />
                            </div>
                          </li>
                          <ul className={`option-element-rooms-edu monthly-plan-options display-${displayEdu13}`}>
                            <li onClickCapture={() => {
                              onClickLi('strategy-28');
                            }}>Documentos</li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-29');
                            }}>HTTPI Digital</li>
                          </ul>
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-30');
                          }}>
                            Plano Mensal
                          </li>
                          {/* <li className='option-element'>
                          Plano de Trabalho docente
                        </li> */}
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-31');
                          }}>
                            Rotina Semanal
                          </li>
                          <li className='option-element' onClickCapture={() => {
                            onClickLi('strategy-32');
                          }}>
                            Relatório do Estudante
                          </li>
                        </ul>
                      </>
                    </RuleVerification>
                    <li className='option-element' onClickCapture={() => {
                      onClickLi('strategy-33');
                    }}>
                      Projetos
                    </li>
                    <RuleVerification rule={['professor']}>
                      <li onClickCapture={() => {
                        onClickLi('strategy-47');
                      }}>
                        Relatório Psicológico do Aluno
                      </li>
                    </RuleVerification>
                  </ul>
                </li>
                <RuleVerification rule={['coordenador', 'psicologo']}>
                  <>
                    <li className='option-element' onClick={() => {
                      huddleDisplay(setDisplayEdu11, displayEdu11);
                    }}>
                      <div className='option-element-title-content'>
                        <h1>Ferramentas Administração</h1>
                        <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu11}`} />
                      </div>
                    </li>
                    <li className={'option-element-rooms'}>
                      <ul className={`option-of-rooms display-${displayEdu11}`}>
                        <RuleVerification rule={['coordenador']} >
                          <>
                            <li className='option-element option-education' onClick={() => {
                              onClickLi('strategy-27');
                            }}>
                              <div className='option-element-title-content'>
                                <h1>Alunos que recebem bolsa familia</h1>
                              </div>
                            </li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-9');
                            }}>
                              Registro de Nível de Escrita (Antigo)
                            </li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-51');
                            }}>
                              Registro de Nível de Escrita
                            </li>
                            {/* <li onClickCapture={() => {
                              onClickLi('strategy-48');
                            }}>
                              Diagnostico de Matemática
                            </li> */}
                            <li onClickCapture={() => {
                              onClickLi('strategy-49');
                            }}>
                              Provas
                            </li>
                          </>
                        </RuleVerification>
                        <li className='option-element option-education' onClickCapture={() => {
                          huddleDisplay(setDisplayEdu14, displayEdu14);
                        }}>
                          <div className='option-element-title-content'>
                            <h1>Documentos e Slides</h1>
                            <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu14}`} />
                          </div>
                        </li>
                        <ul className={`option-element-rooms-edu display-${displayEdu14}`}>
                          {/* <li className='option-element option-education' onClickCapture={() => {
                          onClickLi('strategy-35');
                        }}>
                          Legislação
                        </li> */}
                          <RuleVerification rule={['coordenador']}>
                            <>
                              <li className='option-element option-education' onClickCapture={() => {
                                onClickLi('strategy-43');
                              }}>
                                Horários
                              </li>
                              <li className='option-element option-education' onClickCapture={() => {
                                onClickLi('strategy-44');
                              }}>
                                Calendário
                              </li>
                              <li className='option-element option-education' onClickCapture={() => {
                                huddleDisplay(setDisplayEdu15, displayEdu15);
                              }}>
                                <div className='option-element-title-content'>
                                  <h1>ATA</h1>
                                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu15}`} />
                                </div>
                              </li>
                              <ul className={`option-element-rooms-edu monthly-plan-options display-${displayEdu15}`}>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-36');
                                }}>Reunião dos Pais</li>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-37');
                                }}>Reunião Gestão</li>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-38');
                                }}>Reunião Funcionário</li>
                                <li onClickCapture={() => {
                                  onClickLi('strategy-39');
                                }}>Geral</li>
                              </ul>
                            </>
                          </RuleVerification>
                          <li className='option-element option-education' onClickCapture={() => {
                            huddleDisplay(setDisplayEdu16, displayEdu16);
                          }}>
                            <div className='option-element-title-content'>
                              <h1>Slides</h1>
                              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayEdu16}`} />
                            </div>
                          </li>
                          <ul className={`option-element-rooms-edu monthly-plan-options display-${displayEdu16}`}>
                            <li onClickCapture={() => {
                              onClickLi('strategy-40');
                            }}>Eventos</li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-41');
                            }}>Reuniões</li>
                            <li onClickCapture={() => {
                              onClickLi('strategy-42')
                            }}>Geral</li>
                          </ul>
                        </ul>
                      </ul>
                    </li>
                  </>
                </RuleVerification>
              </ul>
            </>
          </RuleVerification>
          <RuleVerification rule={['console', 'psicologo']}>
            <>
              <li className='option-content-1' onClickCapture={() => {
                document.location = document.location.origin + '/pisicology';
              }}>
                <RiMentalHealthLine size='20' color='#fff' />
                <div className='option-tile-content'>
                  <h1>Gen. Psicóloga</h1>
                </div>
              </li>
            </>
          </RuleVerification>
          <RuleVerification rule={['coordenador']}>
            <>
              <li className='Option-content-1' onClickCapture={() => {
                huddleDisplay(setDisplayEdu9, displayEdu9)
              }}>
                <RiListSettingsFill size='20' color='#fff' />
                <div className='option-title-content'>
                  <h1>Sistema</h1>
                  <IoIosArrowDown size='20' className={`icon-arrow display-${displayEdu9}`} />
                </div>
              </li>
              <ul className={`management-options display-${displayEdu9}`}>
                <li className='option-element' onClick={() => {
                  onClickLi('strategy-2');
                }}>
                  Adicionar Administradores
                </li>
                <li className='option-element' onClick={() => {
                  onClickLi('strategy-3');
                }}>
                  Adicionar Professores
                </li>
                <li className='option-element' onClick={() => {
                  onClickLi('strategy-34');
                }}>
                  Adicionar Nutricionista
                </li>
                <li className='option-element' onClick={() => {
                  onClickLi('strategy-46');
                }}>
                  Adicionar Psicóloga(o)
                </li>
                <li className='option-element' onClick={() => {
                  onClickLi('strategy-26');
                }}>
                  Criar Notificação
                </li>
              </ul>
            </>
          </RuleVerification>
          <RuleVerification rule={['estudante']}>
            <>
              <li className='option-content-1' onClickCapture={() => {
                onClickLi('strategy-49');
              }}>
                <FaFileAlt size='20' color='#fff' />
                <div className='option-tile-content'>
                  <h1>Minhas Provas</h1>
                </div>
              </li>
            </>
          </RuleVerification>
        </ul>
      </nav>
    </>
  );
}

import lscache from 'lscache';
import { type SecretaryDocumentType } from '../types/secretary-documents';
import { type UserClientProtocol } from '../types/User/UserAPI-protocol';
import Validator from '../Validator';
import UploadSecretaryDocumentApi from './uploadSecretaryDocuments-api';

type OptionType = 'projects-and-programs' | 'educational-laws';

class SecretaryDocumentsCostumer {
	constructor(
		private readonly inputs: NodeListOf<HTMLInputElement> | undefined = undefined,
		private readonly secretaryDocumentId = '',
	) {}

	get _user(): UserClientProtocol {
		const user = lscache.get('user') as UserClientProtocol;

		return user;
	}

	async post(option: OptionType): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: true
			};
		}

		const file = this.inputs.item(2).files?.item(0);

		if (!file) {
			return {
				error: true
			};
		}

		const fileName = file.name ?? '';
    const description = this.inputs.item(0).value;
		const date = this.inputs.item(1).value;

		const user = this._user;

		let newSecretaryDocumentApi: SecretaryDocumentType[] = [];

    const uploadSecretaryDocumentApi = new UploadSecretaryDocumentApi();

		await uploadSecretaryDocumentApi.post(option, {
			file,
      description,
			date,
			name: fileName,
			userId: user.id ?? '',
			userName: user.userName
		}).then(response => {
			newSecretaryDocumentApi = response;
		}).catch(e => {
			//
		});

		return newSecretaryDocumentApi;
	}

	async put(option: OptionType): Promise<any> {
		if (!this.inputs) {
			return {
				error: true
			};
		}

		const validation = new Validator(this.inputs);

		if (validation.init()) {
			return {
				error: false
			};
		}

		const file = this.inputs.item(0).files?.item(0);

		if (!file) {
			return {
				error: false
			};
		}

		const fileName = file.name ?? '';

		let newSecretaryDocumentApi: SecretaryDocumentType[] = [];

    const uploadSecretaryDocumentApi = new UploadSecretaryDocumentApi();

		await uploadSecretaryDocumentApi.put(option, {
			file,
			name: fileName,
			secretaryDocumentId: this.secretaryDocumentId,
		}).then(response => {
			newSecretaryDocumentApi = response;
		});

		return newSecretaryDocumentApi;
	}
}

export default SecretaryDocumentsCostumer;

/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UpLoadElementarySchoolHTTPICostumer from '../../../class/UploadElementarySchoolHTTPI/upload-HTTPI-custumer';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import LoadContent from '../../../components/Load-content';

interface FormEditWeeklyPlanProps {
  setDisplayFormDescribeHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolHTTPI: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  elementarySchoolHTTPI: ElementarySchoolDecentWorkType[]
  displayFormDescribeHTTPI: boolean
  elementId: string
}

export default function FormDescribeHTTPI({ setDisplayFormDescribeHTTPI, setElementarySchoolHTTPI, elementarySchoolHTTPI, displayFormDescribeHTTPI, elementId }: FormEditWeeklyPlanProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const huddleCancel = () => {
    setDisplayFormDescribeHTTPI(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const textArea = form.querySelectorAll('textarea').item(0);

    const elementarySchoolDecentMonthlyPlan = new UpLoadElementarySchoolHTTPICostumer(undefined, elementId, textArea);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    elementarySchoolDecentMonthlyPlan.postDescription().then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }

      const indexElement = elementarySchoolHTTPI.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }
      elementarySchoolHTTPI[indexElement] = response

      setElementarySchoolHTTPI([...elementarySchoolHTTPI]);

      textArea.value = ''
      setDisplayFormDescribeHTTPI(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  return (
    <div className={`form-describe-school-teaching-work-plan display-${String(displayFormDescribeHTTPI)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          Adicionar Comentário
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormDescribeHTTPI(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-describe-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="textArea-describe-input-kindergarten-school-word-plan">
            Comentário
          </label>
          <textarea id='textArea-describe-input-kindergarten-school-word-plan' />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable new-cap */
/* eslint-disable react/jsx-key */
/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import React, { useState, useEffect } from 'react';
import type IndividualCardReportType from '../../../class/types/IndividualCardReporting';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import { useParams } from 'react-router-dom';
import InputSelect from '../../../components/input-select';
import LoadContent from '../../../components/Load-content';
import generatePDF from '../service/generetePDF';
import RoomsApi from '../../../class/Room/RoomsApi';
import 'jspdf-autotable';
import './style.scss';
import ATACardReportAPI from '../../../class/ATACardReport/ATACardReportAPI';

const twoMonthsName = [
  'twoMonths-one',
  'twoMonths-two',
  'twoMonths-tree',
  'twoMonths-for',
]

export default function Table(): React.ReactElement {
  const [cardReport, setCardReport] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentType[] | undefined>();
  const [student, setStudent] = useState<IndividualCardReportType>();
  const [displayGeral, setDisplayGeral] = useState(true);
  const [twoMonths, setTwoMonths] = useState('Geral');
  const { id, roomName } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!id) {
      return;
    }

    const twoMonthsFind = twoMonthsName[Number(twoMonths[0]) - 1];

    if (!twoMonthsFind) {
      return;
    }

    setIsLoading(true)

    ATACardReportAPI.getATACardReport(id, twoMonthsFind).then((response: any[]) => {
      setIsLoading(false);
      setCardReport(response);
    })
  };

  const printElement = () => {
    generatePDF(roomName ?? '', twoMonths, student?.studentName ?? '');
  }

  const huddleSetTwoMonths = (option: any) => {
    if (option === 'Geral') {
      setDisplayGeral(true);
    } else {
      setDisplayGeral(false);
    }
    setTwoMonths(option);
  }

  const returnMatterFrequency = (matters: any[], matterName: string, key: 'missed' | 'present') => {
    let frequency: string = '-';

    const formatMatterName = matterName.toLowerCase().replaceAll(' ', '');

    matters.map(value => {
      const formatName = value.matterName.toLowerCase().replaceAll(' ', '').slice(0, formatMatterName.length);

      if (formatMatterName.includes(formatName)) {
        frequency = `${value.frequency[key] ?? '-'}`;
      }

      return value;
    });

    return frequency;
  }

  const returnPorcentualFrequency = (matters: any[], matterName: string) => {
    const missed = Number(returnMatterFrequency(matters, matterName, 'missed'));
    const present = Number(returnMatterFrequency(matters, matterName, 'present'));

    if (isNaN(missed) || isNaN(present)) {
      return '-'
    }

    if (missed === 0 && present === 0) {
      return '-'
    }

    const sum = missed + present;

    return `${Math.round((missed / sum) * 100)}%`;
  }

  const getDate = () => {
    return new Date().toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    });
  }

  const formatMatterName = (name: string) => {
    const formatName = name.split(' ');
    if (formatName.length > 1) {
      return `${formatName[0][0]}${formatName[1][0]}`;
    }

    return formatName[0].slice(0, 3);
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
  }, []);

  return (
    <div className='table-container-ata-card-report'>
      <section className='header-content'>
        <h1>ATA - {roomName}</h1>
        <form action="POST" className='form-search-student-individual-card-report' onSubmit={huddleSubmit}>
          <InputSelect
            options={[
              '1º Bimestre',
              '2º Bimestre',
              '3º Bimestre',
              '4º Bimestre',
            ]}
            title='Bimestre: '
            defaultValue='Escolha um Bimestre'
            cb={huddleSetTwoMonths}
            id={(students?.map(value => value.id ?? ''))}
          />
          {!isLoading ? (
            <button className='search-button' type='submit'>Buscar</button>
          ) : (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 20,
                  },
                  container: {
                    width: 50,
                    marginLeft: 50,
                  }
                }
              }
            />
          )}
          <button className='search-button' type='button' onClickCapture={printElement}>Imprimir</button>
        </form>
      </section>
      {!isLoading ? '' : (
        <LoadContent
          isLoading={isLoading}
          style={
            {
              container: {
                marginTop: 60,
                height: 100,
              }
            }
          }
        />
      )}
      {isLoading || cardReport.length === 0 ? '' : (
        <section className='ata-card-report' id='ata-card-report-main'>
        <div className='ata-card-report-information-table'>
          <ul className='ata-card-report-information-table-list'>
            <li>
              <h1>Sala: {roomName}</h1>
            </li>
            <li>
              <h1>Bimestre: {twoMonths}</h1>
            </li>
            <li>
              <h1>Data: {getDate()}</h1>
            </li>
          </ul>
        </div>
        <div className='ata-card-report-header-table'>
          <ul className='ata-card-report-header-table-title'>
            <li>
              <h1>N</h1>
            </li>
            <li>
              <h1>Nome do Estudante</h1>
            </li>
            <li>
              <h1>F - Por</h1>
              <div className='ata-card-report-frequency-title'>
                <h2>N</h2>
                <h2>%</h2>
              </div>
            </li>
            <li>
              <h1>F - Mat</h1>
              <div className='ata-card-report-frequency-title'>
                <h2>N</h2>
                <h2>%</h2>
              </div>
            </li>
            <li>
              <h1>Materia</h1>
              <div>
                <ul className='ata-card-report-matter-list'>
                  {(cardReport[0] && cardReport[0].matterName) ? (
                    cardReport[0].matterName.map((name: string) => {
                      return (
                        <li>
                          <h1>{formatMatterName(name)}</h1>
                        </li>
                      )
                    })
                  ) : ''}
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className='ata-card-report-main-table'>
          {cardReport.length > 0 ? (
            cardReport.map((cardReport, index: number) => {
              return (
                <ul className='ata-card-report-main-table-student-list'>
            <li>
              <h1>{index + 1}</h1>
            </li>
            <li>
              <h1>{cardReport.studentName}</h1>
            </li>
            <li>
              <h1>{returnMatterFrequency(cardReport.matter, 'por', 'missed') === '0' ? '-' : returnMatterFrequency(cardReport.matter, 'por', 'missed')}</h1>
              <h1>{returnPorcentualFrequency(cardReport.matter, 'por')}</h1>
            </li>
            <li>
              <h1>{returnMatterFrequency(cardReport.matter, 'mat', 'missed') === '0' ? '-' : returnMatterFrequency(cardReport.matter, 'mat', 'missed')}</h1>
              <h1>{returnPorcentualFrequency(cardReport.matter, 'mat')}</h1>
            </li>
            <li>
              <ul className='ata-card-report-main-table-matter-list'>
                {cardReport.matter ? (
                  cardReport.matterName.map((name: any, index: number) => {
                    const matter = cardReport.matter.find((value: any) => value.matterName === name);

                    if (!matter) {
                      return (
                        <li>
                          <h1>-</h1>
                        </li>
                      )
                    }
                    if (!matter.cardReport) {
                      return (
                        <li>
                          <h1>-</h1>
                        </li>
                      )
                    }
                    if (matter.cardReport.grade.length === 0) {
                      return (
                        <li>
                          <h1>-</h1>
                        </li>
                      )
                    }
                    return (
                      <li>
                        <h1>{matter.cardReport.grade}</h1>
                      </li>
                    )
                  })
                ) : ''}
              </ul>
            </li>
          </ul>
              )
            })
          ) : ''}
        </div>
        <div className='ata-card-report-tag'>
          <ul className='ata-card-report-tag-list'>
            {cardReport[0].matterName.map((name: string) => {
              return (
                <li>
                  <h1>{formatMatterName(name)} - {name}</h1>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import EmptyComponent from '../../../components/Empty-component';
import FormAddSecretaryResource from '../form-add-resource';
import { type SecretaryResourceType } from '../../../class/types/secretary-resource';
import SecretaryResourceApi from '../../../class/secretary-resource/secretary-resource-api';
import { useParams } from 'react-router-dom';
import SecretaryResourceTableElement from '../../../components/secretary-resource-management/secretary-resource-table-element';
import FormEditSecretaryResource from '../form-edit-resource';

export interface SecretaryResourceProps {
  display: string
  setResources: React.Dispatch<React.SetStateAction<SecretaryResourceType[]>>
}

export default function SecretaryResource({ display, setResources }: SecretaryResourceProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [displayAdd, setDisplayAdd] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [secretaryResource, setSecretaryResource] = useState<SecretaryResourceType[]>([]);
  const [element, setElement] = useState<SecretaryResourceType | undefined>();

  const { id } = useParams();

  useEffect(() => {
    if (display === 'false' || secretaryResource.length > 0 || !id) {
      return;
    }

    setIsLoading(true);
    const secretaryResourceApi = new SecretaryResourceApi();

    secretaryResourceApi.getAll(id).then(response => {
      setSecretaryResource(response);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  }, [display]);

  useEffect(() => {
    setResources(secretaryResource);
  }, [secretaryResource])

  return (
    <section className='secretary-resource'>
      <FormAddSecretaryResource
        display={displayAdd}
        setDisplay={setDisplayAdd}
        elements={secretaryResource}
        setElements={setSecretaryResource}
      />
      <FormEditSecretaryResource
        display={displayEdit}
        setDisplay={setDisplayEdit}
        elements={secretaryResource}
        setElements={setSecretaryResource}
        element={element}
      />
      <div className='secretary-resource-title'>
        <h1>Ações</h1>
      </div>
      <div className='secretary-resource-btn'>
        <button onClick={() => {
          setDisplayAdd(!displayAdd);
        }}>Adicionar Recurso</button>
      </div>
      <div className='secretary-resource-table-content'>
        <div className='secretary-resource-table-title'>
          <ul className='secretary-resource-table-ul'>
            <li className='secretary-resource-table-ul-li'>Nº</li>
            <li className='secretary-resource-table-ul-li'>Ação</li>
            <li className='secretary-resource-table-ul-li'>Data de Inicio</li>
            <li className='secretary-resource-table-ul-li'>Fonte do Recurso</li>
            <li className='secretary-resource-table-ul-li'>Situação</li>
          </ul>
        </div>
        <div className='secretary-resource-table-main'>
          {isLoading
            ? (<LoadContent
              isLoading={isLoading}
              style={{
                container: {
                  // marginTop: '4rem',
                },
              }} />)
            : (secretaryResource.length > 0
              ? secretaryResource.map((resource, index) => {
                return (
                  <SecretaryResourceTableElement
                    elements={secretaryResource}
                    index={index}
                    secretaryResource={resource}
                    setDisplayEdit={setDisplayEdit}
                    setElement={setElement}
                    setElements={setSecretaryResource}
                    key={resource.id}
                  />
                )
              })
              : <EmptyComponent>click em (adicionar recurso) para adicionar um novo recurso</EmptyComponent>)}
        </div>
      </div>
    </section>
  );
}

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import uploadKindergartenSchoolATAApi from '../../../class/UploadKindergartenSchoolATA/upload-ATA-api';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import { type StudentReportType } from '../../../class/types/StudentReport';
import EmptyComponent from '../../../components/Empty-component';
import StudentReportDisplayInformation from '../ATA-display-information';
import InputSelect from '../../../components/input-select';
import filterByTwoMonths from '../service/filterByTwoMonths';
import ContentTableUploadFileATA from '../../../components/UploadATA';
import ReadCheckingKindergartenSchoolATA from '../../../components/read-checking-upload-ATA';
import FormAddKindergartenSchoolATA from '../form-add-ATA';
import FormEditKindergartenSchoolATA from '../form-edit-ATA';
import LoadContent from '../../../components/Load-content';
import RuleVerification from '../../../components/rule-verification';
import FileDeliveryDate from '../file-delivery-data';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [kindergartenSchoolATA, setKindergartenSchoolATA] = useState<StudentReportType[]>([]);
  const [displayFormAddKindergartenSchoolATA, setDisplayKindergartenSchoolATA] = useState(false);
  const [displayATAInfo, setDisplayATAInfo] = useState(false);
  const [displayFormEditKindergartenATA, setDisplayEditKindergartenATA] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<StudentReportType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();
  const [displayReadCheckingATA, setDisplayReadCheckingATA] = useState(false);
  const [displayFileDelivery, setDisplayFileDelivery] = useState(false);

  const [element, setElement] = useState<StudentReportType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayKindergartenSchoolATA(true);
  };

  const huddleDisplayFileDelivery = () => {
    setDisplayFileDelivery(!displayFileDelivery);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    let filterATAByTwoMonths = kindergartenSchoolATA;

    if (inputs.item(2).value !== 'Todos' && inputs.item(2).value !== 'escolha um bimestre') {
      filterATAByTwoMonths = filterByTwoMonths(kindergartenSchoolATA, inputs.item(2).value)
    }

    const filterATA = new FilterPlan([
      inputs.item(0),
      inputs.item(1),
      inputs.item(3),
      inputs.item(4)
    ], filterATAByTwoMonths);

    setElementFiltered(filterATA.searchPlans());
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false' || kindergartenSchoolATA.length > 0) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadKindergartenSchoolATAApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const ATA = response.filter(value => value.issuer?.userId === user.id)
          setKindergartenSchoolATA(ATA);
          setElementFiltered(ATA);
          return;
        }

        setKindergartenSchoolATA(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    });
  }, [display]);

  useEffect(() => {
    setElementFiltered(kindergartenSchoolATA);
  }, [kindergartenSchoolATA]);

  return (
    <div className='table-container-new-kindergarten-school-ATA'>
      <h1 className='title-table'>Ensino Infantil - ATA</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingKindergartenSchoolATA
        displayReadChecking={displayReadCheckingATA}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadCheckingATA}
        setElementaryStudentReport={setKindergartenSchoolATA}
        type='kindergarten-school'
      />
      <FormAddKindergartenSchoolATA
        displayFormAddATA={displayFormAddKindergartenSchoolATA}
        setDisplayFormAddATA={setDisplayKindergartenSchoolATA}
        setKindergartenSchoolATA={setKindergartenSchoolATA}
      />
      <FormEditKindergartenSchoolATA
        displayFormEditATA={displayFormEditKindergartenATA}
        setDisplayFormEditATA={setDisplayEditKindergartenATA}
        setKindergartenSchoolATA={setKindergartenSchoolATA}
        setDisplayEditElement={setDisplayEditKindergartenATA}
        elementId={elementId}
      />
      <StudentReportDisplayInformation
        displayATAInfo={displayATAInfo}
        setDisplayATAInfo={setDisplayATAInfo}
        element={element}
      />
      <FileDeliveryDate
        display={displayFileDelivery}
        setDisplay={setDisplayFileDelivery}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar relatório do estudante</button>
        <RuleVerification rule={['coordenador']}>
          <button onClick={huddleDisplayFileDelivery}>Visualizar dados de entrega</button>
        </RuleVerification>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='name-content'>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='professor' />
          </div>
          <div className='search-input-content input-content'>
            <input type='text' placeholder='nome da sala' />
          </div>
          <InputSelect
            options={[
              'Todos',
              '1º Bimestre',
              '2º Bimestre',
              '3º Bimestre',
              '4º Bimestre'
            ]}
            title=''
            defaultValue='escolha um bimestre'
          />
        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-date-content-one input-data'>
            <input type='date' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-date-content-two input-data'>
            <input type='date' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'>Bimestre</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar ATA) para adicionar um nova ATA</EmptyComponent>
                  : elementFiltered?.map((kindergartenSchoolATA, index: number) => (
                    <ContentTableUploadFileATA
                      index={index}
                      kindergartenSchoolSchoolATA={kindergartenSchoolATA}
                      setDisplayATA={setDisplayATAInfo}
                      setDisplayChecking={setDisplayReadCheckingATA}
                      setDisplayEditElement={setDisplayEditKindergartenATA}
                      setElementId={setElementId}
                      setElement={setElement}
                      setKindergartenSchoolATA={setKindergartenSchoolATA}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}

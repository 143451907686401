import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import { type ElementarySchoolMonthlyPlanPut, type ElementarySchoolMonthlyPlanPost, type ElementarySchoolMonthlyPlanType } from '../types/Upload_ElementarySchoolMonthlyPlan';

class UploadElementarySchoolMonthlyPlanApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post({ file, classRoom, date, name, userName, userId }: ElementarySchoolMonthlyPlanPost): Promise<ElementarySchoolMonthlyPlanType | undefined> {
		let newElementarySchoolDecentWork: ElementarySchoolMonthlyPlanType | undefined;

		const formDate = new FormData();

		formDate.append('fileMonthlyPlan', file);
    formDate.append('classRoom', classRoom);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/file/elementaryschool/monthlyPlan/',
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolMonthlyPlanType;
		});

		return newElementarySchoolDecentWork;
	}

	async postDescription(decentWorkId: string, description: string, userId: string, userName: string): Promise<ElementarySchoolMonthlyPlanType | undefined> {
		let newElementaryMonthlyPlan: ElementarySchoolMonthlyPlanType | undefined;

		await axios.post(`${this.href}/description/elementaryschool/monthlyPlan/${decentWorkId}`, {
      userId,
      userName,
      date: this._date,
      description
		}).then(response => {
			newElementaryMonthlyPlan = response.data as ElementarySchoolMonthlyPlanType;
		});

		return newElementaryMonthlyPlan;
	}

  async deleteDescription(decentWorkId: string, descriptionId: string, userId: string): Promise<ElementarySchoolMonthlyPlanType | undefined> {
    let newElementaryMonthlyPlan: ElementarySchoolMonthlyPlanType | undefined;

    await axios
        .delete(
            `${this.href}/description/elementaryschool/monthlyPlan/${decentWorkId}/${descriptionId}/${userId}`
        )
        .then((response) => {
            newElementaryMonthlyPlan = response.data as ElementarySchoolMonthlyPlanType;
        });

    return newElementaryMonthlyPlan;
}

  async setChecking(monthlyPlanId: string): Promise<ElementarySchoolMonthlyPlanType | undefined> {
    let newElementarySchoolMonthlyPlan: ElementarySchoolMonthlyPlanType | undefined;

    await axios.post(`${this.href}/checking/elementaryschool/monthlyPlan/${monthlyPlanId}`).then(response => {
      newElementarySchoolMonthlyPlan = response.data as ElementarySchoolMonthlyPlanType;
    });

    return newElementarySchoolMonthlyPlan;
  }

	async put({ file, name, monthlyPlanId }: ElementarySchoolMonthlyPlanPut): Promise<ElementarySchoolMonthlyPlanType | undefined> {
		let newElementarySchoolDecentWork: ElementarySchoolMonthlyPlanType | undefined;

		const formData = new FormData();

		formData.append('fileMonthlyPlan', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/file/elementaryschool/monthlyPlan/${monthlyPlanId}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolMonthlyPlanType;
		});

		return newElementarySchoolDecentWork;
	}

	async get(): Promise<ElementarySchoolMonthlyPlanType[]> {
		let newElementarySchoolDecentWork: ElementarySchoolMonthlyPlanType[] = [];

		await axios.get(this.href + '/file/elementaryschool/monthlyPlan/').then(response => {
			newElementarySchoolDecentWork = response.data as ElementarySchoolMonthlyPlanType[];
		}).catch(e => {
			//
		});

		return newElementarySchoolDecentWork;
	}

	async delete(elementId: string, fileName: string): Promise<{id: string} | undefined> {
		let newElementarySchoolDecentWork: {id: string} | undefined;

		await axios.delete(this.href + `/file/elementaryschool/monthlyPlan/${elementId}`, {
			data: {
				fileName
			}
		}).then((response) => {
			newElementarySchoolDecentWork = response.data as {id: string};
		}).catch(e => {
			//
		});

		return newElementarySchoolDecentWork;
	}

  async getData(teacherId: string): Promise<ElementarySchoolMonthlyPlanType[]> {
    let newElementarySchoolMonthlyPlan: ElementarySchoolMonthlyPlanType[] = [];

    await axios.get(this.href + `/file/elementaryschool/monthlyplan/get_data_by_id/${teacherId}`).then(response => {
      newElementarySchoolMonthlyPlan = response.data;
    });

    return newElementarySchoolMonthlyPlan;
  }
}

export default new UploadElementarySchoolMonthlyPlanApi();

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import LoadContent from '../../../components/Load-content';
import FilterAdmLegislation from '../service/filterSecretaryDocument';
import UploadSecretaryDocumentApi from '../../../class/UploadSecretaryDocuments/uploadSecretaryDocuments-api';
import { type SecretaryDocumentType } from '../../../class/types/secretary-documents';
import FormAddSecretaryDocuments from '../form-add-secretary-documents';
import FormEditSecretaryDocuments from '../form-edit-secretary-documents';
import SecretaryDocumentDisplayInformation from '../secretary-documents-display-information';
import ContentTabeSecretaryDocuments from '../../../components/secretaryDocuments';

const elementDefault: SecretaryDocumentType = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  description: {
    content: ''
  },
};

interface TableProps {
  display: string
  title: string
  titleEdit: string
  titleAdd: string
  titleInfo: string
  emptyText: string
  type: 'projects-and-programs' | 'educational-laws'
}

export default function Table({ display, title, titleEdit, titleAdd, emptyText, titleInfo, type}: TableProps): React.ReactElement {
  const [secretaryDocuments, setSecretaryDocuments] = useState<SecretaryDocumentType[]>([]);
  const [displayFormAddSecretaryDocument, setDisplayFormAddSecretaryDocument] = useState(false);
  const [displaySecretaryDocumentInfo, setDisplaySecretaryDocumentInfo] = useState(false);
  const [displayFormEditSecretaryDocument, setDisplayEditSecretaryDocument] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [elementFiltered, setElementFiltered] = useState<SecretaryDocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainElement = useRef<any>();

  const [element, setElement] = useState<SecretaryDocumentType>(elementDefault);

  const huddleCreateNewElement = () => {
    setDisplayFormAddSecretaryDocument(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const elementsFiltered = FilterAdmLegislation(secretaryDocuments, inputs);

    setElementFiltered(elementsFiltered);
  };
  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    const uploadSecretaryDocumentApi = new UploadSecretaryDocumentApi();

    setIsLoading(true);
    uploadSecretaryDocumentApi.get(type).then((response) => {
      setSecretaryDocuments(response);
      setElementFiltered(response);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  }, [display]);

  useEffect(() => {
    setElementFiltered(secretaryDocuments);
  }, [secretaryDocuments])

  return (
    <div className='table-container-adm-ATA-parents-meeting'>
      <h1 className='title-table'>{title}</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <FormAddSecretaryDocuments
        displayFormAddSecretaryDocument={displayFormAddSecretaryDocument}
        setDisplayFormAddSecretaryDocument={setDisplayFormAddSecretaryDocument}
        setSecretaryDocument={setSecretaryDocuments}
        secretaryDocuments={secretaryDocuments}
        title={titleAdd}
        type={type}
      />
      <FormEditSecretaryDocuments
        displayFormEditSecretaryDocuments={displayFormEditSecretaryDocument}
        setDisplayFormEditSecretaryDocuments={setDisplayEditSecretaryDocument}
        setSecretaryDocument={setSecretaryDocuments}
        secretaryDocuments={secretaryDocuments}
        setDisplayEditElement={setDisplayEditSecretaryDocument}
        elementId={elementId}
        title={titleEdit}
        type={type}
      />
      <SecretaryDocumentDisplayInformation
        displaySecretaryDocumentsInfo={displaySecretaryDocumentInfo}
        setDisplaySecretaryDocuments={setDisplaySecretaryDocumentInfo}
        titleInfo={titleInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Documento</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome do documento' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='emissor' />
        </div>
        <div className='search-input-content input-content input-date-content-one input-data'>
          <input type='date' />
        </div>
        <h2>A</h2>
        <div className='search-input-content input-content input-date-content-two input-data'>
          <input type='date' />
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Nome do documento</li>
          <li className='header-table-ul-li'>Emissor</li>
          <li className='header-table-ul-li'>Data</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>{emptyText}</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork, index: number) => (
                    <ContentTabeSecretaryDocuments
                      index={index}
                      secretaryDocument={elementarySchoolDecentWork}
                      secretaryDocuments={secretaryDocuments}
                      setDisplaySecretaryDocuments={setDisplaySecretaryDocumentInfo}
                      setDisplayEditElement={setDisplayEditSecretaryDocument}
                      setElementId={setElementId}
                      setElement={setElement}
                      setSecretaryDocuments={setSecretaryDocuments}
                      type={type}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}

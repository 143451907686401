import React, { useState } from 'react';
import './styles.scss';
import LoadContent from '../../Load-content';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';
import SecretaryResourceApi from '../../../class/secretary-resource/secretary-resource-api';
import { useParams } from 'react-router-dom';
import { type SecretaryResourceType } from '../../../class/types/secretary-resource';

interface SecretaryResourceTableElementProps {
  index: number
  secretaryResource: SecretaryResourceType
  elements: SecretaryResourceType[]
  setElement: React.Dispatch<React.SetStateAction<SecretaryResourceType | undefined>>
  setElements: React.Dispatch<React.SetStateAction<SecretaryResourceType[]>>
  setDisplayEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SecretaryResourceTableElement({ index, secretaryResource, elements, setElements, setElement, setDisplayEdit }: SecretaryResourceTableElementProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const {id} = useParams();

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC'
    });
  }

  const deleteResourceManagement = () => {
    if (!secretaryResource.id || !id) {
      return;
    }

    const secretaryResourceApi = new SecretaryResourceApi();

    setIsLoading(true);
    secretaryResourceApi.delete(id, secretaryResource.id).then(response => {
      setIsLoading(false);

      const elementIndex = elements.findIndex((value) => value.id === response?.id);

      if (typeof elementIndex !== 'number') {
        return;
      }

      elements.splice(elementIndex, 1);

      setElements([...elements]);
    }).catch(e => {
      //
    });
  };

  const huddleEdit = () => {
    setElement(secretaryResource);
    setDisplayEdit(true)
  }
  return (
    <div className='secretary-resource-table-element'>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h1>{index + 1}</h1>
        </li>
        <li className='content-table-ul-li'>
          <h1>{secretaryResource.action}</h1>
        </li>
        <li className='content-table-ul-li'>
          <h1>{formatDate(secretaryResource.initialDate)}</h1>
        </li>
        <li className='content-table-ul-li'>
        <h1>{secretaryResource.resourceSource}</h1>
        </li>
        <li className='content-table-ul-li'>
          <h1>{secretaryResource.status ?? 'Não Concluído'}</h1>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={deleteResourceManagement}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}

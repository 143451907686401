/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import UploadWorkshopApi from '../../../class/UploadWorkShopHTTPI/upload-HTTPI-api';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import BackToTop from '../../../components/back-to-top/back-to-top';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import ContentTableWorkshopHTTPI from '../../../components/workshop-HTTPI';
import ReadCheckingHTTPI from '../../../components/read-checking-HTTPI';
import FormAddWorkshopHTTPI from '../form-add-HTTPI';
import FormEditWorkshopHTTPI from '../form-edit-HTTPI';
import WorkshopHTTPIDisplayInformation from '../HTTPI-display-information';
import FormDescribeWorkshopHTTPI from '../form-description-HTTPI';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayFormDescribeWorkshopHTTPI, setDisplayDescribeWorkshopHTTPI] = useState(false);
  const [displayFormEditWorkshopHTTPI, setDisplayEditWorkshopHTTPI] = useState(false);
  const [displayFormAddWorkshopHTTPI, setDisplayElementaryWorkshopHTTPI] = useState(false);
  const [displayWorkshopHTTPIInfo, setDisplayWorkshopHTTPIInfo] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workshopHTTPI, setWorkshopHTTPI] = useState<ElementarySchoolDecentWorkType[]>([]);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayReadChecking, setDisplayReadChecking] = useState<boolean>(false);

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();
  const huddleCreateNewElement = () => {
    setDisplayElementaryWorkshopHTTPI(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, workshopHTTPI);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }
    if (display === 'false' || workshopHTTPI.length > 0) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      UploadWorkshopApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const weeklyPlan = response.filter(value => value.issuer?.userId === user.id)
          setWorkshopHTTPI(weeklyPlan);
          setElementFiltered(weeklyPlan);
          return;
        }
        setWorkshopHTTPI(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(workshopHTTPI);
  }, [workshopHTTPI]);

  return (
    <div className='table-container-workshop-httpi'>
      <h1 className='title-table'>Oficina - HTTPI</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingHTTPI
        displayReadChecking={displayReadChecking}
        elementarySchoolHTTPI={workshopHTTPI}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setWorkshopHTTPI}
        type='workshop'
      />
      <FormAddWorkshopHTTPI
        displayFormAddWorkshopHTTPI={displayFormAddWorkshopHTTPI}
        setDisplayFormAddWorkshopHTTPI={setDisplayElementaryWorkshopHTTPI}
        setWorkshopHTTPI={setWorkshopHTTPI}
        workshopHTTPI={workshopHTTPI}
      />
      <FormEditWorkshopHTTPI
        displayFormEditWorkShopHTTPI={displayFormEditWorkshopHTTPI}
        workshopHTTPI={workshopHTTPI}
        setDisplayFormEditWorkshopHTTPI={setDisplayEditWorkshopHTTPI}
        setElementarySchoolWorkshopHTTPI={setWorkshopHTTPI}
        setDisplayEditElement={setDisplayEditWorkshopHTTPI}
        elementId={elementId}
      />
      <FormDescribeWorkshopHTTPI
        workshopHTTPI={workshopHTTPI}
        displayFormDescribeWorkshopHTTPI={displayFormDescribeWorkshopHTTPI}
        setDisplayFormDescribeWorkshopHTTPI={setDisplayDescribeWorkshopHTTPI}
        setWorkshopHTTPI={setWorkshopHTTPI}
        elementId={elementId}
      />
      <WorkshopHTTPIDisplayInformation
        setElement={setElement}
        workshopHTTPI={workshopHTTPI}
        setWorkshopHTTPI={setWorkshopHTTPI}
        displayWorkshopHTTPIInfo={displayWorkshopHTTPIInfo}
        setDisplayWorkshopHTTPIInfo={setDisplayWorkshopHTTPIInfo}
        element={element}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar HTTPI</button>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-container'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem',
              }
            }}
          />
          : (
            (display === 'true' && isLoading === false)
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar HTTPI) para adicionar um nova HTTPI</EmptyComponent>
                  : elementFiltered?.map((HTTPI: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableWorkshopHTTPI
                      index={index}
                      elementarySchoolHTTPIS={workshopHTTPI}
                      elementarySchoolHTTPI={HTTPI}
                      setDisplayDescribeKindergartenSchoolHTTPI={setDisplayDescribeWorkshopHTTPI}
                      setDisplayEditElement={setDisplayEditWorkshopHTTPI}
                      setDisplayHTTPI={setDisplayWorkshopHTTPIInfo}
                      setDisplayReadChecked={setDisplayReadChecking}
                      setElement={setElement}
                      setElementId={setElementId}
                      setKindergartenSchoolHTTPI={setWorkshopHTTPI}
                      key={HTTPI.id}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}

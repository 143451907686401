/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import LoadContent from '../../../components/Load-content';
import { IoCloseOutline } from 'react-icons/io5';
import './styles.scss';
import { type SecretaryResourceType } from '../../../class/types/secretary-resource';
import SecretaryResourceCostumer from '../../../class/secretary-resource/secretary-resource-costumer';
import { useParams } from 'react-router-dom';
import InputSelect from '../../../components/input-select';

interface FormEditSecretaryResourceProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  elements: SecretaryResourceType[]
  setElements: React.Dispatch<React.SetStateAction<SecretaryResourceType[]>>
  element: SecretaryResourceType | undefined
}

// eslint-disable-next-line spaced-comment
export default function FormEditSecretaryResource({ display, setDisplay, elements, setElements, element }: FormEditSecretaryResourceProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {id} = useParams();
  const action = useRef<null | HTMLInputElement>(null)
  const resourceSource = useRef<null | HTMLInputElement>(null)
  const initialDate = useRef<null | HTMLInputElement>(null)
  const [status, setStatus] = useState<string>();
  const [deleteSelect, setDeleteSelect] = useState(false);

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id || !element?.id) {
      return;
    }

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');
    const secretaryResource = new SecretaryResourceCostumer(inputs, id);

    setIsLoading(true);
    secretaryResource.put(element.id).then(response => {
      if (!response) {
        setIsLoading(false);
        setDisplay(false)
        return
      }

      const indexElement = elements.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }

      elements[indexElement] = response;

      setElements([...elements]);

      setIsLoading(false);
      setDisplay(false)
    }).catch(e => {
      //
    });
  }

  const huddleClickClose = (bol: boolean) => {
    setDisplay(bol);
  }

  useEffect(() => {
    if (!action.current || !resourceSource.current || !initialDate.current || !element) {
      return;
    }

    setDeleteSelect(true);

    action.current.value = element?.action;
    resourceSource.current.value = element?.resourceSource;
    initialDate.current.value = element?.initialDate;
    setStatus(`${element.status}`);

    setTimeout(() => {
      setDeleteSelect(false);
    }, 200)
  }, [display, element])

  return (
    <div className={`form-add-secretary-resource display-${display}`}>
      <form method='POST' className='form-add-element' id='form-add-element' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Editar recurso
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='secretary-resource-action'>Ação:</label>
            <input type='text' id='secretary-resource-action' placeholder='Ação' ref={action} />
          </div>
          <div className='input-content'>
            <label htmlFor='secretary-resource-source'>Fonte de recurso:</label>
            <input type='text' id='secretary-resource-source' placeholder='Digite a fonte de recurso' ref={resourceSource} />
          </div>
          <div className='input-date-content'>
            <label htmlFor='secretary-resource-initial-date'>Data de inicio:</label><br />
            <input type="date" id='secretary-resource-initial-date' ref={initialDate}/>
          </div>
          <div className='input-date-content-select'>
            <InputSelect
              defaultValue={status}
              title='Situação'
              options={['Não Concluído', 'Concluído']}
              deleteAll={deleteSelect}
            />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    huddleClickClose(false);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  )
}

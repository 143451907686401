/* eslint-disable no-multi-spaces */

import React, { useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Nav from '../../components/secretary-resource-management/Nav-secretary-resource';
import SecretaryResource from '../../container/secretary-resource/secretary-resource';
import SecretaryResourceGraph from '../../container/secretary-resource-graph/secretary-resource-graph';
import { type SecretaryResourceType } from '../../class/types/secretary-resource';

type Elements = Array<{
  name: string
  boolean: string
}>;

function ResourceManagement() {
  const [elements, setElements] = useState([
    {
      name: 'resource-element-1',
      boolean: 'true'
    },
    {
      name: 'resource-element-2',
      boolean: 'false'
    },
  ]);

  const [display1, setDisplay1] = useState<string>('true');
  const [display2, setDisplay2] = useState<string>('false');

  const [resources, setResources] = useState<SecretaryResourceType[]>([]);

  const hundleSetElements = (elementsParams: Elements) => {
    setElements(elementsParams);
    setDisplay1(elements[0].boolean);
    setDisplay2(elements[1].boolean);
  };

  return (
    <div className='App'>
      <Header />
      <main className='main-container-plans'>
        <Nav setElements={hundleSetElements} elements={elements} />
        <div className='content-containers'>
          <div className='content' id={`display-${display1}`}>
            <SecretaryResource setResources={setResources} display={display1} />
          </div>
          <div className='content' id={`display-${display2}`}>
            <SecretaryResourceGraph display={display2} resource={resources} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default ResourceManagement;

import { type SecretaryResourceType } from '../types/secretary-resource';
import Validator from '../Validator';
import SecretaryResourceApi from './secretary-resource-api';

export default class SecretaryResourceCostumer {
  constructor(
    private readonly inputs: NodeListOf<HTMLInputElement>,
    private readonly monitoringId: string,
  ) {}

  async post(): Promise<SecretaryResourceType | undefined> {
    const action = this.inputs.item(0).value;
    const resourceSource = this.inputs.item(1).value;
    const initialDate = this.inputs.item(2).value;

    const validation = new Validator(this.inputs);

    if (validation.validation(false, true)) {
      return undefined;
    }

    const secretaryResourceManagementApi = new SecretaryResourceApi();
    let data: SecretaryResourceType | undefined = {
      action,
      resourceSource,
      initialDate
    };

    await secretaryResourceManagementApi.post(data, this.monitoringId).then(response => {
      data = response;
    }).catch(e => {
      //
    });

    return data;
  }

  async put(resourceId: string): Promise<SecretaryResourceType | undefined> {
    const action = this.inputs.item(0).value;
    const resourceSource = this.inputs.item(1).value;
    const initialDate = this.inputs.item(2).value;
    const status = this.inputs.item(3).value;

    const secretaryResourceManagement = new SecretaryResourceApi();

    let data: SecretaryResourceType | undefined = {
      action,
      resourceSource,
      initialDate,
      status
    };

    await secretaryResourceManagement.put(data, this.monitoringId, resourceId).then(response => {
      data = response;
    }).catch(e => {
      //
    });

    return data;
  }
}

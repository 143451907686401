/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useRef } from 'react';

import UploadKindergartenSchoolDecentWorkApi from '../../../class/UploadKindergartenSchoolSchoolDecentWork/upload-decent-work-api';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import ContentTableElementaryDecentWork from '../../../components/decent-work';
import DecentWorkDisplayInformation from '../work-plan-display-information';
import EmptyComponent from '../../../components/Empty-component';

import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import FormDescribeDecentWork from '../form-description-work-plan';
import FormEditDecentWork from '../form-edit-work-plan';
import FormAddDecentWork from '../form-add-work-plan';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import ReadChecking from '../../../components/read-checking-decent-work';
import LoadContent from '../../../components/Load-content';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [kindergartenSchoolDecentWorks, setKindergartenSchoolDecentWorks] = useState<ElementarySchoolDecentWorkType[]>([]);
  const [displayFormAddKindergartenShoolDecentWork, setDisplayKindergartenSchoolDecentWork] = useState(false);
  const [displayDecentWorkInfo, setDisplayDecentWorkInfo] = useState(false);
  const [displayFormEditKindergartenDecentWork, setDisplayEditKindergartenDecentWork] = useState(false);
  const [displayFormDescribeKindergartenDecentWork, setDisplayDescribeKindergartenDecentWork] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [displayReadChecking, setDisplayReadChecking] = useState(false);

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();

  const huddleCreateNewElement = () => {
    setDisplayKindergartenSchoolDecentWork(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, kindergartenSchoolDecentWorks);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    // UserAPI.getPermission(user.id).then(permission => {
    //   UploadKindergartenSchoolDecentWorkApi.get().then((response) => {
    //     setIsLoading(false);
    //     if (permission === 'professor') {
    //       const decentWork = response.filter(value => value.issuer?.userId === user.id)
    //       setKindergartenSchoolDecentWorks(decentWork);
    //       setElementFiltered(decentWork);
    //       return;
    //     }

    //     setKindergartenSchoolDecentWorks(response);
    //     setElementFiltered(response);
    //   }).catch((e) => {
    //     //
    //   });
    // }).catch(e => {
    //   //
    // })
  }, [display]);

  useEffect(() => {
    setElementFiltered(kindergartenSchoolDecentWorks)
  }, [kindergartenSchoolDecentWorks])

  return (
    <div className='table-container-kindergarten-school-teaching-work-plan'>
      <h1 className='title-table'>Ensino Infantil - Plano de Trabalho docente</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadChecking
        displayReadChecking={displayReadChecking}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setKindergartenSchoolDecentWorks}
        type='kindergarten-school'
      />
      <FormAddDecentWork
        displayFormAddDecentWork={displayFormAddKindergartenShoolDecentWork}
        setDisplayFormAddDecentWork={setDisplayKindergartenSchoolDecentWork}
        setElementarySchoolDecentWork={setKindergartenSchoolDecentWorks}
        elementarySchoolDecentWork={kindergartenSchoolDecentWorks}
      />
      <FormEditDecentWork
        displayFormEditDecentWork={displayFormEditKindergartenDecentWork}
        setDisplayFormEditDecentWork={setDisplayEditKindergartenDecentWork}
        setElementarySchoolDecentWork={setKindergartenSchoolDecentWorks}
        setDisplayEditElement={setDisplayEditKindergartenDecentWork}
        elementId={elementId}
      />
      <FormDescribeDecentWork
        displayFormDescribeMonthlyPlan={displayFormDescribeKindergartenDecentWork}
        setDisplayFormDescribeMonthlyPlan={setDisplayDescribeKindergartenDecentWork}
        setElementarySchoolMonthlyPlan={setKindergartenSchoolDecentWorks}
        elementId={elementId}
      />
      <DecentWorkDisplayInformation
        displayDecentWorkInfo={displayDecentWorkInfo}
        setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
        element={element}
      />
      {/* <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar plano de trabalho docente</button>
      </div> */}
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-content'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem',
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar plano de trabalho docente) para adicionar um novo plano de trabalho docente</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryDecentWork
                      index={index}
                      setDisplayReadChecking={setDisplayReadChecking}
                      elementarySchoolDecentWork={elementarySchoolDecentWork}
                      setDisplayEditElement={setDisplayEditKindergartenDecentWork}
                      setDisplayDescribeElementaryDecentWork={setDisplayDescribeKindergartenDecentWork}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
                      setElementarySchoolDecentWork={setKindergartenSchoolDecentWorks}
                      type={'kindergartenSchool'}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}

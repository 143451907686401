import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import { type SecretaryDocumentsPostType, type SecretaryDocumentsPutType, type SecretaryDocumentType } from '../types/secretary-documents';
import lscache from 'lscache';

type OptionType = 'projects-and-programs' | 'educational-laws';

export default class UploadSecretaryDocumentApi {
  private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	private get _date(): string {
		const date = new Date();

		return date.toLocaleString('pt-BR', {
			timeZone: 'America/Sao_Paulo',
			dateStyle: 'short',
			timeStyle: 'short'
		});
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async post(option: OptionType, { file, description, date, name, userName, userId }: SecretaryDocumentsPostType): Promise<SecretaryDocumentType[]> {
		let secretaryDocuments: SecretaryDocumentType[] = [];

		const formDate = new FormData();

		formDate.append('fileSecretaryDocument', file);
    formDate.append('description', description);
		formDate.append('date', date);
		formDate.append('localDate', this._date);
		formDate.append('name', name);
		formDate.append('userName', userName);
		formDate.append('userId', userId);

		await axios({
			method: 'post',
			url: this.href + '/secretary-documents/' + option,
			data: formDate,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			secretaryDocuments = response.data as SecretaryDocumentType[];
		});

		return secretaryDocuments;
	}

	async put(option: OptionType, { file, name, secretaryDocumentId }: SecretaryDocumentsPutType): Promise<SecretaryDocumentType[]> {
		let secretaryDocuments: SecretaryDocumentType[] = [];

		const formData = new FormData();

		formData.append('fileSecretaryDocument', file);
		formData.append('name', name);

		await axios({
			method: 'put',
			url: `${this.href}/secretary-documents/${secretaryDocumentId}/${option}`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(response => {
			secretaryDocuments = response.data as SecretaryDocumentType[];
		});

		return secretaryDocuments;
	}

	async get(option: OptionType): Promise<SecretaryDocumentType[]> {
		let secretaryDocuments: SecretaryDocumentType[] = [];

		await axios.get(this.href + '/secretary-documents/' + option).then(response => {
			secretaryDocuments = response.data as SecretaryDocumentType[];
		}).catch(e => {
			//
		});

		return secretaryDocuments;
	}

	async delete(option: OptionType, elementId: string, fileName: string): Promise<{id: string} | undefined> {
		let secretaryDocuments;

		await axios.delete(this.href + `/secretary-documents/${elementId}/${option}`, {
			data: {
				fileName
			}
		}).then((response) => {
			secretaryDocuments = response.data as SecretaryDocumentType[];
		}).catch(e => {
			//
		});

		return secretaryDocuments;
	}
}

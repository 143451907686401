/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import clearAllInputs from './service/clear-all-inputs';
import StudentCostumer from '../../../class/Student/student-custumer';
import { IoIosArrowDown } from 'react-icons/io';
import type { StateType } from '../../../class/types/IBGEType/IBGEApiTypes';
import IBGEApi from '../../../class/IBGE/IBGEApi';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';

import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayEditElement: boolean
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setStudents: React.Dispatch<React.SetStateAction<any[]>>
  setStudentsFiltered: React.Dispatch<React.SetStateAction<any[]>>
  students: StudentType[]
  elementId: string | undefined
}

export default function FormEditElement({ displayEditElement, students, setDisplayEditElement, elementId, setStudents, setStudentsFiltered }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [option, setOption] = useState<string>('');
  const [displayOptionsTwo, setDisplayOptionsTwo] = useState<boolean>(false);
  const [displayOptionsThree, setDisplayOptionsThree] = useState<boolean>(false);
  const [optionTwo, setOptionTwo] = useState<string>('');
  const [optionThree, setOptionThree] = useState<string>('');
  const [optionFour, setOptionFour] = useState<string>('');
  const [displayOptionsTree, setDisplayOptionsTree] = useState<boolean>(false);
  const [displayOptionsFour, setDisplayOptionsFour] = useState<boolean>(false);
  const [optionTree, setOptionTree] = useState<string>();
  const [state, setState] = useState<StateType[]>([]);
  const [date, setDate] = useState<string>('');
  const [student, setStudent] = useState<StudentType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const studentNameRef = useRef<HTMLInputElement | null>(null);
  const responsibleRef = useRef<HTMLInputElement | null>(null);
  const contactNumberRef = useRef<HTMLInputElement | null>(null);
  const raRef = useRef<HTMLInputElement | null>(null);
  const districtRef = useRef<HTMLInputElement | null>(null);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const studentCostumer = new StudentCostumer(inputs);

    if (elementId) {
      setIsLoading(true);
      studentCostumer.put(elementId).then(response => {
        setStudents(response);
        setStudentsFiltered(response);
        setDisplayEditElement(false);
        clearAllInputs();
        setIsLoading(false);
      });
    }
  };

  const hundleClick = () => {
    setDisplayOptions(!displayOptions);
  };

  const hundleClickTwo = () => {
    setDisplayOptionsTwo(!displayOptionsTwo);
  };

  const hundleClickTree = () => {
    setDisplayOptionsTree(!displayOptionsTree);
  };
  const hundleClickThree = () => {
    setDisplayOptionsThree(!displayOptionsThree);
  };

  const hundleClickClose = () => {
    setDisplayElement(false);
    setDisplayEditElement(false);
    clearAllInputs();
  };

  const hundleClickOption = (
    e: React.MouseEvent<HTMLUListElement>,
    setOption: React.Dispatch<React.SetStateAction<string>>,
    setDisplayOptions: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOption(target.textContent);
      setDisplayOptions(false);
    }
  };

  const hundleClickOptionTwo = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOptionTwo(target.textContent);
      setDisplayOptionsTwo(false);
    }
  };

  const hundleClickOptionTree = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOptionTree(target.textContent);
      setDisplayOptionsTree(false);
    }
  };

  const hundleClickDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    setDate(target.value);
  };

  const hundleClickFour = () => {
    setDisplayOptionsFour(!displayOptionsFour)
  }

  useEffect(() => {
    setDisplayElement(displayEditElement);

    IBGEApi.getUf().then(response => {
      setState(response);
    });

    students.map(student => {
      if (student.id === elementId) {
        setStudent(student);
        setDate(student.birthday);
        setOptionTwo(student.ra.uf);
        setOptionThree(student.monetaryAid ? 'Sim' : 'Nao');
        setOptionFour(student?.ethnicity ? student.ethnicity : 'Etnia');
        (studentNameRef.current ?? {value: ''}).value = student?.studentName;
        (responsibleRef.current ?? {value: ''}).value = student?.responsible;
        (contactNumberRef.current ?? {value: ''}).value = student?.responsibleNumber;
        (raRef.current ?? {value: ''}).value = student?.ra.content;
        (districtRef.current ?? {value: ''}).value = student?.district;
      }

      return student;
    })
  }, [displayEditElement]);
  return (
    <div className={`form-edit-container-student display-${displayElement}`}>
      <form
        method='POST'
        className='form-edit-element'
        id='form-edit-element-student'
        onSubmit={hundleSubmit}
      >
        <div className='header-form-edit-element'>
          <h1>Editar</h1>
        </div>
        <div className='body-form-edit-element'>
          <div className='input-content'>
            <label htmlFor='strategy'>Nome do aluno:</label>
            <input
              type='text'
              id='strategy'
              placeholder={'Nome do estudante'}
              ref={studentNameRef}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='strategy'>Data de nascimento:</label>
            <input
              type='date'
              id='strategy'
              value={date}
              onChange={hundleClickDate}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Responsável pelo aluno:</label>
            <input
              type='text'
              id='responsavel'
              ref={responsibleRef}
              placeholder={'Responsável pelo aluno'}
            />
          </div>
          <div className='input-content'>
            <label htmlFor='goal'>Numero de contato:</label>
            <input
              type='text'
              id='goal'
              ref={contactNumberRef}
              placeholder={'Numero de contato'}
            />

          </div>
          <div className='input-content'>
            <label htmlFor='responsavel-meta'>RA:</label>
            <div className='ra-input-list'>
              <input
                type='text'
                id='responsavel-meta'
                ref={raRef}
                placeholder={'RA do aluno'}
              />
              <div className='input-content input-list-content'>
                <div className='input-element-content' onClick={hundleClickTwo}>
                  <input
                    type='text'
                    id='situation'
                    value={optionTwo}
                    disabled
                  />
                  <IoIosArrowDown className={`icon-arrow display-${displayOptionsTwo}`} size={20} />
                </div>
                <ul
                  className='input-option-list'
                  id={`display-${displayOptionsTwo}`}
                  onClick={e => {
                    hundleClickOptionTwo(e);
                  }}
                >
                  {state.map(state => <li key={state.id}>{state.sigla}</li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel-meta'>Bairro:</label>
            <input
              type='text'
              id='responsavel-meta'
              ref={districtRef}
              placeholder={'bairro'}
            />
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='edit-sext-input'>Sexo:</label>
            <div className='input-element-content' onClick={hundleClick}>
              <input
                type='text'
                id='edit-sext-input'
                value={!option ? student?.sex : option}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptions}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptions}`}
              onClick={e => {
                hundleClickOption(e, setOption, setDisplayOptions);
              }}
            >
              <li>Masculino</li>
              <li>Feminino</li>
            </ul>
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='etnia-input'>Etnia:</label>
            <div className='input-element-content' onClick={hundleClickFour}>
              <input
                type='text'
                id='etnia-input'
                value={!optionFour ? student?.ethnicity : optionFour}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionsFour}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptionsFour}`}
              onClick={e => {
                hundleClickOption(e, setOptionFour, setDisplayOptionsFour);
              }}
            >
              <li>Branca</li>
              <li>Preta</li>
              <li>Parda</li>
              <li>Amarela</li>
              <li>Indígena</li>
            </ul>
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='monetary-aid2'>Aluno Recebe Bolsa Familia?:</label>
            <div className='input-element-content' onClick={hundleClickThree}>
              <input
                type='text'
                id='monetary-aid'
                value={!optionThree ? 'Não' : optionThree}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionsThree}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptionsThree}`}
              onClick={e => {
                hundleClickOption(e, setOptionThree, setDisplayOptionsThree);
              }}
            >
              <li>Sim</li>
              <li>Não</li>
            </ul>
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='situation'>Situação:</label>
            <div className='input-element-content' onClick={hundleClickTree}>
              <input
                type='text'
                id='situation'
                value={!optionTree ? student?.situation : optionTree}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionsTree}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptionsTree}`}
              onClick={e => {
                hundleClickOptionTree(e);
              }}
            >
              <li>Ativo</li>
              <li>Transferido</li>
            </ul>
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose();
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

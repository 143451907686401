import { type SecretaryResourceManagementType } from '../../../class/types/secretary-resource-management/secretary-resource-management';
import React, { useState } from 'react';
import './styles.scss';
import SecretaryResourceManagementApi from '../../../class/secretary-resource-management/secretary-resource-management-api';
import LoadContent from '../../Load-content';
import { MdDelete } from 'react-icons/md';
import { FiEdit2 } from 'react-icons/fi';

interface SecretaryResourceManagementTableElementProps {
  index: number
  secretaryResourceManagement: SecretaryResourceManagementType
  elements: SecretaryResourceManagementType[]
  setElement: React.Dispatch<React.SetStateAction<SecretaryResourceManagementType | undefined>>
  setElements: React.Dispatch<React.SetStateAction<SecretaryResourceManagementType[]>>
  setDisplayEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SecretaryResourceManagementTableElement({ index, secretaryResourceManagement, elements, setElements, setElement, setDisplayEdit }: SecretaryResourceManagementTableElementProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC'
    });
  }

  const huddleClick = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    if (!secretaryResourceManagement.id) {
      return;
    }

    document.location = `/gerenciamento-de-recursos/${secretaryResourceManagement.id}`
  };

  const deleteResourceManagement = () => {
    if (!secretaryResourceManagement.id) {
      return;
    }

    const secretaryResourceManagementApi = new SecretaryResourceManagementApi();

    setIsLoading(true);
    secretaryResourceManagementApi.delete(secretaryResourceManagement.id).then(response => {
      setIsLoading(false);

      const elementIndex = elements.findIndex((value) => value.id === response.resourceManagementId);
      if (typeof elementIndex !== 'number') {
        return;
      }

      elements.splice(elementIndex, 1);

      setElements([...elements]);
    }).catch(e => {
      //
    })
  };

  const huddleEdit = () => {
    setElement(secretaryResourceManagement);
    setDisplayEdit(true)
  }
  return (
    <div className='secretary-resource-management-table-element' onClickCapture={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h1>{index + 1}</h1>
        </li>
        <li className='content-table-ul-li'>
          <h1>{secretaryResourceManagement.title}</h1>
        </li>
        <li className='content-table-ul-li'>
          <h1>{formatDate(secretaryResourceManagement.date)}</h1>
        </li>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={deleteResourceManagement}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
      </ul>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-redeclare */
import Validator from '../Validator';
import SecretaryResourceManagementApi from './secretary-resource-management-api';
import { type SecretaryResourceManagementType } from '../types/secretary-resource-management/secretary-resource-management';

export default class SecretaryResourceManagementCostumer {
  constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {}

  async post(): Promise<SecretaryResourceManagementType | undefined> {
    const title = this.inputs.item(0).value;
    const date = this.inputs.item(1).value;

    const validation = new Validator(this.inputs);

    if (validation.validation(false, true)) {
      return undefined;
    }

    const secretaryResourceManagementApi = new SecretaryResourceManagementApi();

    let data: undefined | SecretaryResourceManagementType;

    await secretaryResourceManagementApi.post(title, date).then(response => {
      data = response;
    }).catch(e => {
      //
    });

    return data;
  }

  async put(id: string): Promise<SecretaryResourceManagementType | undefined> {
    const title = this.inputs.item(0).value;
    const date = this.inputs.item(1).value;

    const validation = new Validator(this.inputs);

    if (validation.validation(false, true)) {
      return undefined;
    }

    const secretaryResourceManagement = new SecretaryResourceManagementApi();

    let data: undefined | SecretaryResourceManagementType;

    await secretaryResourceManagement.put(title, date, id).then(response => {
      data = response;
    }).catch(e => {
      //
    });

    return data;
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React from 'react';
import './style.scss';

import { AiFillHome } from 'react-icons/ai';

import { BsFillClipboard2CheckFill } from 'react-icons/bs';
import { PiGraph } from 'react-icons/pi';
import { RiBookletFill } from 'react-icons/ri';
import { SiBookstack } from 'react-icons/si';
import { MdAutoGraph } from 'react-icons/md';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
}

export default function Nav({ setElements, elements }: NavProps): React.ReactElement {
  const huddleDisplay = (setDisplay: React.Dispatch<React.SetStateAction<boolean>>, display: boolean) => {
    setDisplay(!display);
  }

  const onClickLi = (elementName: string) => {
    const elementsResected = resetElements(elements);

    elementsResected.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsResected);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  return (
    <nav className='nav-secretary-resource'>
      <ul className='ul-option'>
        <li className='option-content-1' onClick={e => {
          window.location.href = window.location.origin;
        }}>
          <AiFillHome size='20' color='#fff' />
          <h1 id='estrategy-1'>Inicio</h1>
        </li>
        <li className='option-content-1' onClick={e => {
          onClickLi('resource-element-1');
        }}>
          <SiBookstack size='20' color='#fff' />
          <h1>Recursos</h1>
        </li>
        <li className='option-content-1' onClick={e => {
          onClickLi('resource-element-2');
        }}>
          <MdAutoGraph size='20' color='#fff' />
          <h1>Gráfico</h1>
        </li>
      </ul>
    </nav>
  );
}

/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import './style.scss';

import UpLoadKindergartenSchoolHTTPICostumer from '../../../class/UploadKindergartenSchoolHTTPI/upload-HTTPI-custumer';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import LoadContent from '../../../components/Load-content';

interface FormEditWeeklyPlanProps {
  setDisplayFormEditHTTPI: React.Dispatch<React.SetStateAction<boolean>>
  setKindergartenSchoolHTTPI: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  kindergartenSchoolHTTPI: ElementarySchoolDecentWorkType[]
  displayFormEditHTTPI: boolean
  elementId: string
}

export default function FormEditHTTPI({ setDisplayFormEditHTTPI, setKindergartenSchoolHTTPI, kindergartenSchoolHTTPI, displayFormEditHTTPI, elementId }: FormEditWeeklyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const huddleCancel = () => {
    setDisplayFormEditHTTPI(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolHTTPI = new UpLoadKindergartenSchoolHTTPICostumer(inputs, elementId);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    elementarySchoolHTTPI.put().then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }

      const indexElement = kindergartenSchoolHTTPI.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }
      kindergartenSchoolHTTPI[indexElement] = response;

      setKindergartenSchoolHTTPI([...kindergartenSchoolHTTPI]);

      setDisplayFormEditHTTPI(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  return (
    <div className={`form-edit-kindergarten-school-teaching-HTTPI display-${String(displayFormEditHTTPI)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar HTTPI
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditHTTPI(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="file-edit-input-kindergarten-school-HTTPI">
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id='file-edit-input-kindergarten-school-HTTPI'
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import ContentTableRoom from '../../../components/room-files/ContentTable-room';
import FormAddElement from '../FormAddElement-room';
import EmptyComponent from '../../../components/Empty-component';
import SearchElement from '../../../components/Search-element';
import { useParams } from 'react-router-dom';
import './style.scss';
import StudentAPI from '../../../class/Student/StudentAPI';
import StudentInfo from '../info-student';
import RoomsApi from '../../../class/Room/RoomsApi';
import lscache from 'lscache';
import RuleVerification from '../../../components/rule-verification';
import printStudentByRoom from '../service/print-student-by-room';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState(false);
  const [studentRoom, setStudentRoom] = useState<StudentType[]>([]);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [studentFiltered, setStudentFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [user, setUser] = useState<UserClientProtocol>();
  const [displayStudentInfo, setDisplayStudentInfo] = useState<boolean>(false);

  const { id, roomName } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  const print = () => {
    if (!roomName) {
      return;
    }
    printStudentByRoom(studentRoom, roomName);
  }

  useEffect(() => {
    if (typeof id === 'string') {
      RoomsApi.getAllStudentByRoom(id).then(response => {
        setStudentRoom(response);
        setStudentFiltered(response);
      });
    }

    StudentAPI.get().then(response => {
      setStudents(response);
    });
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  return (
    <div className='table-container-room'>
      <FormAddElement
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        students={students}
        displayCreateNewElement={displayCreateNewElement}
        setStudentRoom={setStudentFiltered}
      />
      <div className='btn-create-new-room-container'>
        <RuleVerification rule={['coordenador', 'professor']}>
          <button type='button' onClick={hundleCreateNewElement}>Adicionar aluno</button>
        </RuleVerification>
        <button type='button' onClick={print}>Imprimir</button>
      </div>
      <StudentInfo
        displayStudentInfo={displayStudentInfo}
        students={students}
        setDisplayStudentInfo={setDisplayStudentInfo}
        elementId={elementId}
      />
      <SearchElement
        elements={studentRoom}
        setElementFiltered={setStudentFiltered}
        elementFiltered={studentFiltered}
        elementKey={'studentName'}
      />
      <div className='header-table-element-action' >
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Responsável</li>
          <li className='header-table-ul-li'>RA</li>
          <li className='header-table-ul-li'>UF</li>
          <li className='header-table-ul-li'>Bairro</li>
          <li className='header-table-ul-li'>Situação</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table-action'>
        {display === 'true'
          ? (
            studentFiltered.length === 0
              ? <EmptyComponent>click em (Adicionar aluno) para adicionar um aluno</EmptyComponent>
              : studentFiltered.map((student, index) => (
                <ContentTableRoom
                  student={student}
                  index={index}
                  setDisplayStudentInfo={setDisplayStudentInfo}
                  setStudentRoom={setStudentFiltered}
                  setElementId={setElementId}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/jsx-key */

import DisplayInformationJustificationOfAbsences from '../display-information-element';
import { type JustificationOfAbsencesType } from '../../../class/types/JustificationOfAbsences';
import ContentTableJustificationOfAbsences from '../../../components/justificatioOfAbsences-files/ContentTable-room';
import FormEditJustificationOfAbsences from '../FormEditElement-room';
import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import JustificationOfAbsencesAPI from '../../../class/JustificationOfAbsences/JustificationOfAbsencesAPI';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import FormAddElement from '../FormAddElement-room';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import RoomsApi from '../../../class/Room/RoomsApi';
import lscache from 'lscache';
import './style.scss';

interface TableProps {
  roomInformation: Record<string, string>
  display: string
}

export default function Table({ roomInformation, display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState(false);
  const [displayEditElement, setDisplayEditElement] = useState(false);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [justificationOfAbsences, setJustificationOfAbsences] = useState<JustificationOfAbsencesType[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayElementInformation, setDisplayElementInformation] = useState<boolean>(false)
  const [element, setElement] = useState<JustificationOfAbsencesType>();
  const [user, setUser] = useState<UserClientProtocol>();

  const { id } = useParams();

  const huddleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    if (!id || !roomInformation.twoMonths || display === 'false') {
      return;
    }

    JustificationOfAbsencesAPI.get(id, roomInformation.matterId, roomInformation.twoMonths).then(response => {
      if ((response as any).error) {
        return;
      }
      setJustificationOfAbsences(response);
    });

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, [roomInformation]);

  return (
    <div className='table-container-room-justification-of-absences'>
      <FormAddElement
        student={students}
        classRoomInfo={roomInformation}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        justificationOfAbsences={justificationOfAbsences}
        displayCreateNewElement={displayCreateNewElement}
        setJustificationOfAbsences={setJustificationOfAbsences}
      />
      <FormEditJustificationOfAbsences
        classRoomInfo={roomInformation}
        elementId={elementId}
        displayEditElement={displayEditElement}
        justificationOfAbsences={justificationOfAbsences}
        setDisplayEditElement={setDisplayEditElement}
        setJustificationOfAbsences={setJustificationOfAbsences}
        student={students}
      />
      <DisplayInformationJustificationOfAbsences
        displayElementInformation={displayElementInformation}
        element={element}
        setDisplayInformation={setDisplayElementInformation}
      />
      <div className='header-table-content'>
        <h1>Justificativas de Falta</h1>
        <h1>Materia: {roomInformation.matterName}</h1>
        <h1>{roomInformation.twoMonthsName}</h1>
      </div>
      <div className='btn-create-new-room-container'>
        <button onClick={huddleCreateNewElement}>Adicionar justificativa de falta</button>
      </div>
      <div className='header-table-element-action' >
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Dias de atestado</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table-action'>
        {display === 'true'
          ? (
            justificationOfAbsences.length === 0
              ? <EmptyComponent>click em (Adicionar justificativa de falta) para adicionar uma justificativa de falta</EmptyComponent>
              : justificationOfAbsences.map((justifications, index) => (
                <ContentTableJustificationOfAbsences
                  justificationOfAbsences={justifications}
                  setDisplayEditElement={setDisplayEditElement}
                  setJustificationOfAbsences={setJustificationOfAbsences}
                  roomInformation={roomInformation}
                  setDisplayElementInformation={setDisplayElementInformation}
                  setElement={setElement}
                  index={index}
                  setElementId={setElementId}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}

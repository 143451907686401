/* eslint-disable multiline-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import IbgeApi from '../../../class/IBGE/IBGEApi';
import displayErro from '../../../class/services/displayError';
import StudentCustumer from '../../../class/Student/student-custumer';
import type { StateType } from '../../../class/types/IBGEType/IBGEApiTypes';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setStudents: React.Dispatch<React.SetStateAction<any[]>>
  setStudentsFiltered: React.Dispatch<React.SetStateAction<any[]>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setStudents, setStudentsFiltered }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [displayOptionsTwo, setDisplayOptionsTwo] = useState<boolean>(false);
  const [displayOptionsThree, setDisplayOptionsThree] = useState<boolean>(false);
  const [displayOptionsFour, setDisplayOptionsFour] = useState<boolean>(false);
  const [option, setOption] = useState<string>('');
  const [optionTwo, setOptionTwo] = useState<string>('');
  const [optionThree, setOptionThree] = useState<string>('');
  const [optionFour, setOptionFour] = useState<string>('');
  const [state, setState] = useState<StateType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const studentCustumer = new StudentCustumer(inputs);

    setIsLoading(true);
    studentCustumer.post().then((response: any) => {
      if (response.error) {
        displayErro(inputs[0], response.error);
        setIsLoading(false)
        return;
      }

      setStudentsFiltered(response)
      setStudents(response);
      clearAllInputs();
      setDisplayCreateNewElement(false);
      setIsLoading(false)
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  const hundleClick = () => {
    setDisplayOptions(!displayOptions);
  };

  const hundleClickTwo = () => {
    setDisplayOptionsTwo(!displayOptionsTwo);
  };
  const hundleClickThree = () => {
    setDisplayOptionsThree(!displayOptionsThree);
  };

  const hundleClickFour = () => {
    setDisplayOptionsFour(!displayOptionsFour)
  }

  const hundleClickOption = (
    e: React.MouseEvent<HTMLUListElement>,
    setOption: React.Dispatch<React.SetStateAction<string>>,
    setDisplayOptions: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOption(target.textContent);
      setDisplayOptions(false);
    }
  };

  const hundleClickOptionTwo = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;

    if (target.textContent) {
      setOptionTwo(target.textContent);
      setDisplayOptionsTwo(false);
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);

    IbgeApi.getUf().then(response => {
      setState(response);
    });
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-student display-${displayElement}`}>
      <form method='POST' className='form-add-element' id='form-add-element-student' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicione um novo aluno
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='student-name'>Nome do aluno:</label>
            <input className='input-element' type='text' id='student-name' placeholder='nome do aluno' />
          </div>
          <div className='input-content'>
            <label htmlFor='student-date'>Data de nascimento:</label>
            <input className='input-element' type='date' id='student-date' placeholder='nome do aluno' />
          </div>
          <div className='input-content'>
            <label htmlFor='responsavel'>Responsável pelo aluno:</label>
            <input className='input-element' type='text' id='responsavel' placeholder='responsavel pelo aluno' />
          </div>
          <div className='input-content'>
            <label htmlFor='numero-de-contato'>Número de contato:</label>
            <input className='input-element' type='text' id='numero-de-contato' placeholder='numero de contato' />
          </div>
          <div className='input-content'>
            <label htmlFor='ra'>RA:</label>
            <div className='input-list-ra'>
              <input className='input-element' type='text' id='ra do aluno' placeholder='ra' />
              <div className='input-content input-list-content'>
                <div className='input-element-content' onClick={hundleClickTwo}>
                  <input
                    type='text'
                    id='situation'
                    value={!optionTwo ? 'UF' : optionTwo}
                    disabled
                  />
                  <IoIosArrowDown className={`icon-arrow display-${displayOptionsTwo}`} size={20} />
                </div>
                <ul
                  className='input-option-list'
                  id={`display-${displayOptionsTwo}`}
                  onClick={e => {
                    hundleClickOptionTwo(e);
                  }}
                >
                  {state.map(state => <li key={state.id}>{state.sigla}</li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className='input-content'>
            <label htmlFor='bairro'>Bairro:</label>
            <input className='input-element' type='text' id='bairro' placeholder='bairro' />
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='sex-input'>Sexo:</label>
            <div className='input-element-content' onClick={hundleClick}>
              <input
                type='text'
                id='sex-input'
                value={!option ? 'sexo' : option}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptions}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptions}`}
              onClick={e => {
                hundleClickOption(e, setOption, setDisplayOptions);
              }}
            >
              <li>Masculino</li>
              <li>Feminino</li>
            </ul>
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='etnia-input'>Etnia:</label>
            <div className='input-element-content' onClick={hundleClickFour}>
              <input
                type='text'
                id='etnia-input'
                value={!optionFour ? 'Etnia' : optionFour}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionsFour}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptionsFour}`}
              onClick={e => {
                hundleClickOption(e, setOptionFour, setDisplayOptionsFour);
              }}
            >
              <li>Branca</li>
              <li>Preta</li>
              <li>Parda</li>
              <li>Amarela</li>
              <li>Indígena</li>
            </ul>
          </div>
          <div className='input-content input-list-content'>
            <label htmlFor='monetary-aid2'>Aluno Recebe Bolsa Familia?:</label>
            <div className='input-element-content' onClick={hundleClickThree}>
              <input
                type='text'
                id='monetary-aid'
                value={!optionThree ? 'Não' : optionThree}
                disabled
              />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionsThree}`} size={20} />
            </div>
            <ul
              className='input-option-list'
              id={`display-${displayOptionsThree}`}
              onClick={e => {
                hundleClickOption(e, setOptionThree, setDisplayOptionsThree);
              }}
            >
              <li>Sim</li>
              <li>Não</li>
            </ul>
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadWeeklyPlanApi from '../../class/UploadElementarySchoolSchoolWeeklytWork/upload-weekly-plan-api';
import uploadKindergartenWeeklyPlanApi from '../../class/UploadKindergartenSchoolWeeklyPlan/upload-weekly-plan-api';
import uploadWorkshopWeeklyPlanApi from '../../class/UploadWorkshopWeeklytWork/upload-weekly-plan-api';
import LoadContent from '../Load-content';

interface ReadCheckingProps {
  displayReadChecking: boolean
  elementId: string
  setElementaryDecentWork: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType[]
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
  type: 'elementary-school' | 'kindergarten-school' | 'workshop'
}

export default function ReadCheckingWeeklyPlan({ displayReadChecking, elementId, type, setElementaryDecentWork, setDisplayReadChecking, elementarySchoolWeeklyPlan }: ReadCheckingProps) {
  const [isLoading, setIsLoading] = useState(false)
  const setCheckingHuddle = () => {
    setIsLoading(true);
    if (type === 'elementary-school') {
      uploadWeeklyPlanApi.setChecking(elementId).then(response => {
        if (!response) {
          return;
        }
        const indexElement = elementarySchoolWeeklyPlan.findIndex((value) => response.id === value.id);

        if (typeof indexElement !== 'number') {
          setIsLoading(false);
          return;
        }
        elementarySchoolWeeklyPlan[indexElement] = response;

        setElementaryDecentWork([...elementarySchoolWeeklyPlan]);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }
    if (type === 'workshop') {
      uploadWorkshopWeeklyPlanApi.setChecking(elementId).then(response => {
        if (!response) {
          return;
        }
        const indexElement = elementarySchoolWeeklyPlan.findIndex((value) => response.id === value.id);

        if (typeof indexElement !== 'number') {
          setIsLoading(false);
          return;
        }
        elementarySchoolWeeklyPlan[indexElement] = response;

        setElementaryDecentWork([...elementarySchoolWeeklyPlan]);
        setDisplayReadChecking(false);
        setIsLoading(false);
      });
      return;
    }
    uploadKindergartenWeeklyPlanApi.setChecking(elementId).then(response => {
      if (!response) {
        return;
      }
      const indexElement = elementarySchoolWeeklyPlan.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }
      elementarySchoolWeeklyPlan[indexElement] = response;

      setElementaryDecentWork([...elementarySchoolWeeklyPlan]);
      setDisplayReadChecking(false);
      setIsLoading(false);
    });
  }

  return (
    <div className={`read-checking-element display-${String(displayReadChecking)}`}>
      <div className='header-form-describe-element-content'>
        <h1>
          confirmação de visualização
        </h1>
        <IoCloseOutline size='25' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }} />
      </div>
      <div className='main-content-element'>
        <h2>Para prosseguir, escolha entre Cancelar ou Confirmar a visualização do documento</h2>
      </div>
      <div className='button-confirm-content'>
        <button className='button-cancel button' onClickCapture={() => {
          setDisplayReadChecking(false);
          setIsLoading(false);
        }}>cancelar</button>
        {isLoading
          ? (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 30,
                    marginRight: 40,
                  },
                  container: {
                    justifyContent: 'flex-end'
                  }
                }
              }
            />
          )
          : (
            <button className='button-confirm button' onClickCapture={setCheckingHuddle} >confirmar</button>
          )}
      </div>
    </div>
  )
}


/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-coordinator';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import UserAPI from '../../../class/User/UserAPI';
import ContentTableCoordinator from '../../../components/coordinator-files/ContentTable-coordinator';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [coordinator, setCoordinator] = useState<any[]>([]);

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    if (display === 'false' || coordinator?.length > 0) {
      return;
    }

    UserAPI.getByPermission('coordinator').then(response => {
      setCoordinator(response);
    });
  }, [display]);

  return (
    <div className='table-container-coordinator'>
      <FormAddElement
        displayCreateNewElement={displayCreateNewElement}
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        setCoordinator={setCoordinator}
      />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={hundleCreateNewElement}>Adicionar Administrador</button>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Administrador</li>
          <li className='header-table-ul-li'>id</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            coordinator?.length === 0
              ? <EmptyComponent>click em (Adicionar coordenador) para adicionar um administrador</EmptyComponent>
              : coordinator?.map((coordinator: any, index: number) => (
                <ContentTableCoordinator
                  index={index}
                  user={coordinator}
                  setCoordinatorCostumer={setCoordinator}
                  key={String(coordinator.id)}
                />
              ))
          )
          : ''}
      </main>
    </div>
  );
}

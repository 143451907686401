import axios from 'axios';
import config from '../../config/config';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import { type SecretaryResourceType } from '../types/secretary-resource';
import lscache from 'lscache';

export default class SecretaryResourceApi {
    private readonly user = lscache.get('user') as UserProtocol & { id: string };
    private get href() {
        return config.apiUrl;
    }

    constructor() {
        try {
            axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
        } catch {
            //
        }
    }

    async post(data: SecretaryResourceType, monitoringId: string): Promise<SecretaryResourceType | undefined> {
      let responseData: undefined;

      await axios.post(this.href + `/secretaryresource/${monitoringId}`, data).then(response => {
        responseData = response.data;
      }).catch(e => {
        //
      });

      return responseData;
    }

    async put(data: SecretaryResourceType, monitoringId: string, resourceId: string): Promise<SecretaryResourceType | undefined> {
      let responseData: undefined;

      await axios.put(this.href + `/secretaryresource/${monitoringId}/${resourceId}`, data).then(response => {
        responseData = response.data;
      }).catch(e => {
        //
      });

      return responseData;
    }

    async delete(monitoringId: string, resourceId: string): Promise<SecretaryResourceType | undefined> {
      let responseData: undefined;

      await axios.delete(this.href + `/secretaryresource/${monitoringId}/${resourceId}`).then(response => {
        responseData = response.data;
      }).catch(e => {
        //
      });

      return responseData;
    }

    async get(monitoringId: string, resourceId: string): Promise<SecretaryResourceType | undefined> {
      let responseData: undefined;

      await axios.get(this.href + `/secretaryresource/get/byId/${monitoringId}/${resourceId}`).then(response => {
        responseData = response.data;
      }).catch(e => {
        //
      });

      return responseData;
    }

    async getAll(monitoringId: string): Promise<SecretaryResourceType[]> {
      let responseData: SecretaryResourceType[] = [];

      await axios.get(this.href + `/secretaryresource/${monitoringId}`).then(response => {
        responseData = response.data;
      }).catch(e => {
        //
      });

      return responseData;
    }
}

import React, { useEffect, useState } from 'react';
import './styles.scss';
import FormAddResourceManagement from '../form-add-resource-management';
import SecretaryResourceManagementApi from '../../../class/secretary-resource-management/secretary-resource-management-api';
import { type SecretaryResourceManagementType } from '../../../class/types/secretary-resource-management/secretary-resource-management';
import SecretaryResourceManagementTableElement from '../../../components/secretary-resource-management/secretary-resource-management-table-element';
import FormEditResourceManagement from '../form-edit-resource-management';
import LoadContent from '../../../components/Load-content';
import EmptyComponent from '../../../components/Empty-component';

interface ResourceManagementProps {
  display: string
}

export default function ResourceManagement({ display }: ResourceManagementProps) {
  const [displayFormAddResourceManagement, setDisplayFormAddResourceManagement] = useState(false);
  const [secretaryResourceManagements, setSecretaryResourceManagements] = useState<SecretaryResourceManagementType[]>([]);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [element, setElement] = useState<SecretaryResourceManagementType | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const secretaryResourceManagementApi = new SecretaryResourceManagementApi();

    if (display === 'false' || secretaryResourceManagements.length > 0) {
      return;
    }

    setIsLoading(true);
    secretaryResourceManagementApi.getAll().then(response => {
      setSecretaryResourceManagements(response);
      setIsLoading(false);
    }).catch(e => {
      //
    })
  }, [display]);

  return (
    <section className='secretary-resource-management'>
      <FormAddResourceManagement
        display={displayFormAddResourceManagement}
        setDisplay={setDisplayFormAddResourceManagement}
        elements={secretaryResourceManagements}
        setElements={setSecretaryResourceManagements}
      />
      <FormEditResourceManagement
        display={displayEdit}
        setDisplay={setDisplayEdit}
        elements={secretaryResourceManagements}
        setElements={setSecretaryResourceManagements}
        element={element}
      />
      <header className='secretary-resource-management-header-content'>
        <h1>Gerenciamento de Recursos</h1>
      </header>
      <div className='secretary-resource-management-btn'>
        <button onClick={() => {
          setDisplayFormAddResourceManagement(!displayFormAddResourceManagement);
        }}>Criar aba de gerenciamento de recursos</button>
      </div>
      <div className='secretary-resource-management-table-content'>
        <div className='secretary-management-table-title'>
          <ul className='secretary-management-table-ul'>
            <li className='secretary-management-table-ul-li'>Nº</li>
            <li className='secretary-management-table-ul-li'>Gerenciamento de Recursos</li>
            <li className='secretary-management-table-ul-li'>Data</li>
          </ul>
        </div>
        <div className='secretary-resource-management-table-main'>
          {isLoading
            ? (<LoadContent
              isLoading={isLoading}
              style={{
                container: {
                  // marginTop: '4rem',
                },
              }} />)
            : (
              secretaryResourceManagements
                ? secretaryResourceManagements.map((resourceManagement, index) => {
                  return <SecretaryResourceManagementTableElement
                    secretaryResourceManagement={resourceManagement}
                    index={index}
                    key={resourceManagement.id}
                    elements={secretaryResourceManagements}
                    setElements={setSecretaryResourceManagements}
                    setDisplayEdit={setDisplayEdit}
                    setElement={setElement}
                  />
                })
                : <EmptyComponent>click em (Criar aba de gerenciamento de recursos) para criar uma aba de gerenciamento de recursos</EmptyComponent>
            )}
        </div>
      </div>
    </section>
  )
}

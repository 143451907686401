import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from '../pages/Home/App';
import Actions from '../pages/Actions';
import Login from '../pages/Login';
import Register from '../pages/Register';
import IsLoggedIn from './middlewares/IsLoggeIn';
import Room from '../pages/Room';
import Plan from '../pages/Plans';
import RecoverByEmail from '../pages/Recover/email';
import ReadingFluency from '../pages/reading-fluency/App';
import RecoverPasswordContainer from '../container/recover-password';
import MunicipalEducationPlanMonitoringSheetPage from '../pages/MunicipalEducationPlanMonitoringSheet';
import Psychologist from '../pages/psychologist';
import StudentExams from '../container/student-exams/student-exams';
import StudentExam from '../pages/student-exams';
import ExamContainer from '../container/exam-container';
import ResourceManagement from '../pages/resource-management';

export default function Routers(): React.ReactElement {
  return (
		<Router>
			<Routes>
				<Route path='/' element={
					<IsLoggedIn path={<App />} />
				} />
				<Route path='/acoes/:id' element={
					<IsLoggedIn path={<Actions />} />
				} />
				<Route path='/salas/:roomName/:id' element={
					<IsLoggedIn path={<Room />} />
				} />
				<Route path='/planos/:id' element={
					<IsLoggedIn path={<Plan />} />
				} />
        <Route path='/ficha-de-monitoramento-do-plano-municipal-de-educacao/:id'
          element={
            <IsLoggedIn path={<MunicipalEducationPlanMonitoringSheetPage />} />
          }
        />
				<Route path='/login' element={<Login />} />
				<Route path='/registrar' element={<Register />} />
				<Route path='/recover/password/by/email' element={<RecoverByEmail />} />
				<Route path='/recover/password/:accessCode' element={<RecoverPasswordContainer />} />
        <Route path='/fluencia-leitora/:id/:roomId/:room/:twoMonths/:yarn' element={
          <IsLoggedIn path={<ReadingFluency />} />
        } />
        <Route path='/pisicology'
          element={
            <IsLoggedIn path={<Psychologist />} />
          }
        />
        <Route
          path='/provas/:id'
          element={
            <IsLoggedIn path={<StudentExam />} />
          }
        />
      <Route
        path='/provas/estudantes/:id'
        element={
          <IsLoggedIn path={<ExamContainer />} />
        }
      />
      <Route
        path='/gerenciamento-de-recursos/:id'
        element={
          <ResourceManagement />
        }
      />
      </Routes>
		</Router>
  );
}
